// src/components/shared/Gallery.js

import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Validation functions remain unchanged
const isValidImage = (url) => {
  return typeof url === 'string' && url.trim() !== '';
};

const isValidMetadata = (metadata) => {
  return (
    metadata &&
    typeof metadata.width === 'number' &&
    typeof metadata.height === 'number' &&
    typeof metadata.format === 'string'
  );
};

// Badge component remains unchanged
const Badge = ({ label, className }) => (
  <div
    className={`badge truncate px-2 py-0.5 text-white text-xs font-semibold uppercase leading-[14px] tracking-wide text-center ${className}`}
  >
    {label}
  </div>
);

Badge.propTypes = {
  label: PropTypes.string.isRequired,
  className: PropTypes.string,
};

Badge.defaultProps = {
  className: '',
};

class Gallery extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedImage: null,
      selectedMetadata: null,
      selectedIndex: -1,
      // Remove 'key' from state as it's no longer needed
    };
  }

  componentDidMount() {
    this.updateSelectedImage();
  }

  componentDidUpdate(prevProps) {
    const imagesChanged = prevProps.images !== this.props.images;
    const metadataChanged = prevProps.imageMetadata !== this.props.imageMetadata;
  
    // Check that images and metadata are valid before proceeding
    if (
      (imagesChanged || metadataChanged) &&
      this.props.images.length > 0 &&
      this.props.imageMetadata.length === this.props.images.length
    ) {
      this.updateSelectedImage(); // Always update to the latest processed image
    }
  }
  
  updateSelectedImage = () => {
    const { images, imageMetadata, onThumbnailClick } = this.props;
  
    if (images.length > 0 && imageMetadata.length === images.length) {
      // Always select the last image in the array (most recently added)
      const selectedIndex = images.length - 1;
      const imageUrl = images[selectedIndex];
      const metadata = imageMetadata[selectedIndex];
      
      // Use Cloudinary URL if available in metadata
      const displayUrl = metadata?.cloudinaryUrl?.includes('cloudinary.com') 
        ? metadata.cloudinaryUrl 
        : imageUrl;
  
      if (isValidImage(displayUrl) && isValidMetadata(metadata)) {
        this.setState(
          {
            selectedImage: displayUrl,
            selectedMetadata: metadata,
            selectedIndex,
            // Remove 'key' from state updates
          },
          () => {
            if (onThumbnailClick) {
              onThumbnailClick(displayUrl, metadata, selectedIndex);
            }
          }
        );
      }
    }
  };
  
  handleThumbnailClick = (imageUrl, metadata, index) => {
    if (this.props.isProcessing) {
      console.log('Thumbnail click blocked - processing in progress');
      return;
    }

    // Enhanced Cloudinary URL logic
    const displayUrl = metadata?.cloudinaryUrl && 
                       metadata.cloudinaryUrl.includes('cloudinary.com') && 
                       metadata.usingCdn !== false
      ? metadata.cloudinaryUrl 
      : imageUrl;

    console.log('Thumbnail clicked:', { 
      originalUrl: imageUrl,
      cloudinaryUrl: metadata?.cloudinaryUrl,
      displayUrl,
      usingCdn: metadata?.usingCdn,
      metadata
    });

    this.setState(
      {
        selectedUrl: displayUrl,
        selectedIndex: index,
        selectedMetadata: metadata
      },
      () => {
        if (this.props.onThumbnailClick) {
          this.props.onThumbnailClick(displayUrl, metadata, index);
        }
      }
    );
  };

  getLabelText = (index) => {
    return index === 0 ? 'ORIGINAL' : `VARIANT ${index}`;
  };

  renderMetadataInfo = () => {
    const { selectedMetadata } = this.state;

    if (!selectedMetadata) {
      return null;
    }

    return (
      <>
        {selectedMetadata.width > 0 && selectedMetadata.height > 0 && (
          <div className="flex justify-between items-center w-full text-13">
            <span className="opacity-50">Resolution:</span>
            <span className="ml-1">
              {selectedMetadata.width} x {selectedMetadata.height}
            </span>
          </div>
        )}

        {selectedMetadata.format && (
          <div className="flex justify-between items-center w-full text-13">
            <span className="opacity-50">Format:</span>
            <span className="ml-1">{selectedMetadata.format}</span>
          </div>
        )}

        {selectedMetadata.isProcessed && selectedMetadata.scaleFactor && (
          <div className="flex justify-between items-center w-full text-13">
            <span className="opacity-50">Scale Factor:</span>
            <span className="ml-1">{selectedMetadata.scaleFactor}x</span>
          </div>
        )}

        {this.props.isUpscalePage && selectedMetadata.isProcessed && (
          <>
            {selectedMetadata.optimizedFor && (
              <div className="flex justify-between items-center w-full text-13">
                <span className="opacity-50">Optimized for:</span>
                <span className="ml-1">
                  {selectedMetadata.optimizedFor.toUpperCase()}
                </span>
              </div>
            )}

            {typeof selectedMetadata.creativity === 'number' && (
              <div className="flex justify-between items-center w-full text-13">
                <span className="opacity-50">Creativity:</span>
                <span className="ml-1">
                  {Math.round(selectedMetadata.creativity)}
                </span>
              </div>
            )}

            {typeof selectedMetadata.composition === 'number' && (
              <div className="flex justify-between items-center w-full text-13">
                <span className="opacity-50">Composition:</span>
                <span className="ml-1">
                  {Math.round(selectedMetadata.composition)}
                  {/* Alternatively, use: {selectedMetadata.composition.toFixed(0)} */}
                </span>
              </div>
            )}

            {typeof selectedMetadata.hdrStrength === 'number' && (
              <div className="flex justify-between items-center w-full text-13">
                <span className="opacity-50">HDR:</span>
                <span className="ml-1">
                  {selectedMetadata.hdrStrength > 0
                    ? selectedMetadata.hdrStrength
                    : 'OFF'}
                </span>
              </div>
            )}

            {/* New Section for Film Grain Strength */}
            {typeof selectedMetadata.filmGrainStrength === 'number' && (
              <div className="flex justify-between items-center w-full text-13">
                <span className="opacity-50">Film Grain:</span>
                <span className="ml-1">
                  {selectedMetadata.filmGrainStrength > 0
                    ? selectedMetadata.filmGrainStrength
                    : 'OFF'}
                </span>
              </div>
            )}
          </>
        )}
      </>
    );
  };

  render() {
    const { images, imageMetadata, isCollapsed, toggleCollapse, isProcessing } = this.props;
    const { selectedImage, selectedMetadata, selectedIndex } = this.state;

    if (!images || images.length === 0 || selectedIndex < 0) {
      // Early return if no images are available or invalid index
      return <div>No images available</div>;
    }

    return (
      <div className="flex flex-row justify-start space-x-6">
        <div className="bg-sidebarBackground w-[541px] rounded-lg border border-border overflow-hidden border-white/10 relative">
          {/* Header */}
          <div
            className="p-4 flex justify-between items-center cursor-pointer"
            onClick={toggleCollapse}
          >
            <div className="text-16">Input and Variants</div>
            <div className="px-1 flex items-center justify-center gap-2.5">
              <div
                className={`w-2 h-2 rounded-full transform transition-transform duration-300 ${
                  isCollapsed ? 'bg-white opacity-30' : 'bg-white'
                }`}
              />
            </div>
          </div>

          {/* Collapsible Content */}
          <div
            className={`transition-max-height duration-500 ease-in-out overflow-hidden ${
              isCollapsed ? 'max-h-0 opacity-0' : 'max-h-screen opacity-100'
            } relative`}
          >
        
            {/* Selected Image and Metadata */}
            <div
              className="p-4 flex flex-row justify-start items-start gap-4"
              style={{ minHeight: '120px' }}
            >
              {/* Selected Image */}
              <div className="relative w-[30%] h-auto">
                <div className="flex justify-center items-center bg-gray-800 rounded object-contain border border-white/20">
                  {isValidImage(selectedImage) ? (
                    <img
                      // Remove the dynamic key to prevent unnecessary re-renders
                      className="w-full h-full rounded object-contain"
                      src={selectedImage}
                      alt={`Selected Image - ${selectedMetadata?.format || 'Unknown'}`}
                    />
                  ) : (
                    <div className="text-white">No Image Selected</div>
                  )}
                </div>
                {/* Position the Badge to intersect the image at the top, more visibly */}
                <div className="absolute top-[-9%] left-1/2 transform -translate-x-1/2 translate-y-1/4">
                  <Badge label={this.getLabelText(selectedIndex)} className="bg-black/70 px-4 py-1 rounded-md" />
                </div>
              </div>

              {/* Metadata Information */}
              <div
                className="flex flex-col grow justify-start items-start space-y-[0.125rem] flex-1"
                style={{ minHeight: '120px' }}
              >
                {this.renderMetadataInfo()}
              </div>
            </div>
            
                {/* Divider */}
                <div
              className=" self-stretch h-px bg-white opacity-10 mx-4 my-1"
              style={{
                bottom: '55%',
                left: 0,
              }}
            />


            {/* Variants Thumbnails */}
            <div className="p-4 max-h-[calc(35vh)] grid grid-cols-3 gap-x-2 gap-y-4 overflow-x-auto overflow-y-auto">
              {images.map((imageUrl, index) => {
                const metadata = imageMetadata[index];
                
                // More robust Cloudinary URL determination
                let displayUrl = imageUrl; // Default
                
                // Use explicit cloudinaryUrl if available
                if (metadata?.cloudinaryUrl && metadata.cloudinaryUrl.includes('cloudinary.com')) {
                  displayUrl = metadata.cloudinaryUrl;
                  // For debugging, log which URL we're using
                  console.log(`Using explicit cloudinaryUrl for thumbnail ${index}`);
                } 
                // If main URL has cloudinary.com in it
                else if (imageUrl && imageUrl.includes('cloudinary.com')) {
                  displayUrl = imageUrl;
                  // Store in metadata for future use and consistency
                  if (metadata && !metadata.cloudinaryUrl) {
                    metadata.cloudinaryUrl = imageUrl;
                  }
                  console.log(`Using implicit cloudinaryUrl for thumbnail ${index}`);
                }
                // Otherwise use the provided URL
                else {
                  console.log(`Using standard URL for thumbnail ${index}`);
                }
                
                // Also ensure usingCdn flag is consistent
                if (metadata && displayUrl.includes('cloudinary.com') && metadata.usingCdn !== true) {
                  metadata.usingCdn = true;
                }
                  
                return (
                  <div
                    key={`thumbnail-${displayUrl}-${index}`}
                    className={`relative rounded-md object-fit border max-h-[30vh] ${
                      selectedIndex === index
                        ? 'border-[2.5px] border-[#d5d5d5] rounded-md'
                        : 'border-white/20'
                    } cursor-pointer flex-shrink-0`}
                    onClick={() =>
                      this.handleThumbnailClick(displayUrl, metadata, index)
                    }
                  >
                    {isValidImage(displayUrl) && isValidMetadata(metadata) ? (
                      <img
                        className="w-full h-full rounded-[4px] object-fit"
                        src={displayUrl}
                        alt={
                          index === 0
                            ? 'Original Image'
                            : `Variant ${index}`
                        }
                      />
                    ) : (
                      <div className="w-full h-full flex items-center justify-center text-white">
                        Invalid Image
                      </div>
                    )}
                    {/* Position the Badge centrally at the top */}
                    <div className="absolute top-[-10%] left-1/2 transform -translate-x-1/2 translate-y-1/4">
                      <Badge label={this.getLabelText(index)} className="bg-black/70 px-4 py-1 rounded-md" />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Gallery.propTypes = {
  images: PropTypes.arrayOf(PropTypes.string).isRequired,
  imageMetadata: PropTypes.arrayOf(
    PropTypes.shape({
      width: PropTypes.number,
      height: PropTypes.number,
      format: PropTypes.string,
      scaleFactor: PropTypes.number,
      isProcessed: PropTypes.bool,
      creativity: PropTypes.number,
      composition: PropTypes.number,
      hdrStrength: PropTypes.number,
      filmGrainStrength: PropTypes.number, // Updated to filmGrainStrength
      optimizedFor: PropTypes.string,
    })
  ).isRequired,
  onThumbnailClick: PropTypes.func, // Ensure this is correctly defined
  isCollapsed: PropTypes.bool,
  toggleCollapse: PropTypes.func,
  isUpscalePage: PropTypes.bool,
  isProcessing: PropTypes.bool, // Added isProcessing prop
};

Gallery.defaultProps = {
  onThumbnailClick: () => {},
  isCollapsed: false,
  toggleCollapse: () => {},
  isUpscalePage: false,
  isProcessing: false, // Default value for isProcessing
};

export default Gallery;