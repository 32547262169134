import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';
import Lottie from 'react-lottie';
import animationData from './Assets/vudoo-logo-animation.json';
import background from './Assets/background.png';
import { signInWithToken } from '../firebase'; // Import the signInWithToken function

// Helper functions for localStorage with fallbacks
const isLocalStorageAvailable = () => {
  try {
    const testKey = '__test__';
    localStorage.setItem(testKey, testKey);
    localStorage.removeItem(testKey);
    console.log('DiscordCallback: localStorage is available');
    return true;
  } catch (e) {
    console.error('DiscordCallback: localStorage is not available:', e);
    return false;
  }
};

// Memory fallback if localStorage isn't available
const memoryStorage = {};

const getStorageItem = (key, defaultValue = null) => {
  try {
    if (isLocalStorageAvailable()) {
      const value = localStorage.getItem(key);
      console.log(`DiscordCallback: Retrieved from localStorage: ${key} = ${value}`);
      return value;
    } else {
      console.log(`DiscordCallback: Retrieved from memoryStorage: ${key} = ${memoryStorage[key]}`);
      return memoryStorage[key] || defaultValue;
    }
  } catch (e) {
    console.error(`DiscordCallback: Error getting ${key} from storage:`, e);
    return defaultValue;
  }
};

const setStorageItem = (key, value) => {
  try {
    if (isLocalStorageAvailable()) {
      localStorage.setItem(key, value);
      console.log(`DiscordCallback: Saved to localStorage: ${key} = ${value}`);
    } else {
      memoryStorage[key] = value;
      console.log(`DiscordCallback: Saved to memoryStorage: ${key} = ${value}`);
    }
    return true;
  } catch (e) {
    console.error(`DiscordCallback: Error setting ${key} in storage:`, e);
    return false;
  }
};

const removeStorageItem = (key) => {
  try {
    if (isLocalStorageAvailable()) {
      localStorage.removeItem(key);
      console.log(`DiscordCallback: Removed from localStorage: ${key}`);
    } else {
      delete memoryStorage[key];
      console.log(`DiscordCallback: Removed from memoryStorage: ${key}`);
    }
    return true;
  } catch (e) {
    console.error(`DiscordCallback: Error removing ${key} from storage:`, e);
    return false;
  }
};

export default function DiscordCallback() {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const handleDiscordCallback = async () => {
      try {
        const urlParams = new URLSearchParams(location.search);
        
        // Check if there's an error parameter (from backend redirect)
        const errorParam = urlParams.get('error');
        if (errorParam) {
          throw new Error(decodeURIComponent(errorParam));
        }
        
        // Check if we're receiving a token directly from the backend redirect
        const token = urlParams.get('token');
        
        if (token) {
          console.log('Received token directly from backend redirect');
          
          try {
            // Sign in with the custom token
            const user = await signInWithToken(token);
            console.log('Successfully signed in with Discord token', user);
            
            // Get the ID token from the user
            const idToken = await user.getIdToken();
            
            // IMPORTANT: Set the token in cookies with a long expiration
            Cookies.set('token', idToken, { expires: 90, path: '/' });
            console.log('Set token cookie with 90-day expiration');
            
            // CRITICAL: Clear any existing Google auth data to prevent auto-switching
            // This is important to prevent falling back to Google auth
            localStorage.removeItem('firebase:authUser');
            sessionStorage.removeItem('firebase:authUser');
            
            // Clear the force manual login flag
            removeStorageItem('forceManualLogin');
            
            // IMPORTANT: Remember the login method - make sure this is set correctly
            // This is the key to maintaining the Discord session
            localStorage.setItem('lastUsed', 'discord');
            setStorageItem('lastUsed', 'discord');
            console.log('Set authentication method to Discord in localStorage');
            
            // Verify with backend to ensure the session is properly established
            const apiBaseUrl = window.location.hostname === 'localhost' 
              ? 'http://localhost:3001' 
              : 'https://www.ek0go8g.vudoo.ai';
              
            const authResponse = await fetch(`${apiBaseUrl}/api/auth`, {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify({ idToken, type: 'discord' }),
            });

            if (!authResponse.ok) {
              throw new Error('Failed to authenticate with server');
            }
            
            console.log('Successfully verified Discord authentication with backend');
            
            // IMPORTANT: Force a page reload before navigating to ensure all auth state is fresh
            // This helps clear any lingering Google auth state
            setTimeout(() => {
              console.log('Redirecting to homepage with Discord authentication');
              // First store that we're using Discord auth in sessionStorage for immediate access
              sessionStorage.setItem('currentAuth', 'discord');
              // Then navigate to home
              navigate('/home');
              // Force reload after a short delay to ensure clean state
              setTimeout(() => {
                window.location.reload();
              }, 500);
            }, 1000);
            return;
          } catch (tokenError) {
            console.error('Error signing in with token:', tokenError);
            throw new Error('Failed to sign in with the provided token');
          }
        }
        
        // If no token, we need to exchange the code
        const code = urlParams.get('code');
        
        if (!code) {
          throw new Error('No authorization code or token provided');
        }
        
        console.log('Received authorization code, exchanging for token');
        
        // Determine which API endpoint to use based on environment
        const apiBaseUrl = window.location.hostname === 'localhost' 
          ? 'http://localhost:3001' 
          : 'https://www.ek0go8g.vudoo.ai';
        
        console.log(`Using API base URL: ${apiBaseUrl}`);
        
        // Exchange the code for a token with the backend
        try {
          const response = await fetch(`${apiBaseUrl}/api/auth/discord/callback`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ code }),
          });
          
          if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.error || 'Failed to authenticate with Discord');
          }
          
          const data = await response.json();
          
          // Sign in with the custom token
          const user = await signInWithToken(data.token);
          console.log('Successfully signed in with Discord token', user);
          
          // Get the ID token from the user
          const idToken = await user.getIdToken();
          
          // IMPORTANT: Clear any existing Google auth data to prevent auto-switching
          localStorage.removeItem('firebase:authUser');
          sessionStorage.removeItem('firebase:authUser');
          
          // Verify with backend
          const authResponse = await fetch(`${apiBaseUrl}/api/auth`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ idToken, type: 'discord' }),
          });

          if (!authResponse.ok) {
            throw new Error('Failed to authenticate with server');
          }
          
          // Set the token in cookies with a long expiration
          Cookies.set('token', idToken, { expires: 90, path: '/' });
          console.log('Set token cookie with 90-day expiration');
          
          // Clear the force manual login flag
          removeStorageItem('forceManualLogin');
          
          // IMPORTANT: Remember the login method - make sure this is set correctly
          localStorage.setItem('lastUsed', 'discord');
          setStorageItem('lastUsed', 'discord');
          console.log('Set authentication method to Discord in localStorage');
          
          // IMPORTANT: Force a page reload before navigating to ensure all auth state is fresh
          setTimeout(() => {
            console.log('Redirecting to homepage with Discord authentication');
            // First store that we're using Discord auth in sessionStorage for immediate access
            sessionStorage.setItem('currentAuth', 'discord');
            // Then navigate to home
            navigate('/home');
            // Force reload after a short delay to ensure clean state
            setTimeout(() => {
              window.location.reload();
            }, 500);
          }, 1000);
        } catch (fetchError) {
          console.error('Error exchanging code for token:', fetchError);
          throw fetchError;
        }
      } catch (error) {
        console.error('Discord callback error:', error);
        setError(error.message || 'Authentication failed. Please try again.');
        
        // Redirect to login page after a delay if there's an error
        setTimeout(() => navigate('/login'), 3000);
      } finally {
        setLoading(false);
      }
    };
    
    handleDiscordCallback();
  }, [location, navigate]);

  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-[#0A0A0A] fixed inset-0 z-50" 
         style={{ 
           backgroundImage: `url(${background})`, 
           backgroundSize: 'cover', 
           backdropFilter: 'blur(8px)',
         }}>
      <div className="absolute inset-0 bg-black bg-opacity-20"></div>
      
      <div className="flex flex-col items-center justify-center space-y-6 relative z-50">
        <div className="flex items-center justify-center">
          <Lottie
            options={{ 
              loop: true, 
              autoplay: true, 
              animationData, 
              rendererSettings: { 
                preserveAspectRatio: 'xMidYMid slice',
              } 
            }}
            height={16}
            width="auto"
            isClickToPauseDisabled={true}
          />
        </div>
        {loading ? (
          <h2 className="text-2xl font-serif text-white animate-pulse text-center">
            Processing Discord login...
          </h2>
        ) : error ? (
          <div className="text-center">
            <h2 className="text-2xl font-serif text-white mb-2">Authentication Error</h2>
            <p className="text-red-500 mb-4">{error}</p>
            <p className="text-gray-400">Redirecting to login page...</p>
          </div>
        ) : (
          <h2 className="text-2xl font-serif text-white animate-pulse text-center">
            Successfully authenticated with Discord!<br />
            Redirecting to your workspace...
          </h2>
        )}
      </div>
    </div>
  );
} 