// src/App.js

import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import LandingPage from './components/LandingPage';
import UploadPage from './components/UploadPage';
import UpscalerPage from './components/UpscalerPage';
import LoginPage from './components/LoginPage';
import ProfilePage from './components/ProfilePage';
import ImageRestorationPage from './components/ImageRestorationPage';
import SuccessPage from './components/SuccessPage';
import DiscordCallback from './components/DiscordCallback';
import '../src/styles/globals.css';
import Cookies from 'js-cookie';
import { ProfileProvider } from './ProfileContext';
import { ActionCardProvider } from './components/ActionCardContext';
import { auth } from './firebase';

// Helper functions for localStorage with fallbacks
const isLocalStorageAvailable = () => {
  try {
    const testKey = '__test__';
    localStorage.setItem(testKey, testKey);
    localStorage.removeItem(testKey);
    console.log('App.js: localStorage is available');
    return true;
  } catch (e) {
    console.error('App.js: localStorage is not available:', e);
    return false;
  }
};

// Memory fallback if localStorage isn't available
const memoryStorage = {};

const getStorageItem = (key, defaultValue = null) => {
  try {
    if (isLocalStorageAvailable()) {
      const value = localStorage.getItem(key);
      console.log(`App.js: Retrieved from localStorage: ${key} = ${value}`);
      return value;
    } else {
      console.log(`App.js: Retrieved from memoryStorage: ${key} = ${memoryStorage[key]}`);
      return memoryStorage[key] || defaultValue;
    }
  } catch (e) {
    console.error(`App.js: Error getting ${key} from storage:`, e);
    return defaultValue;
  }
};

const setStorageItem = (key, value) => {
  try {
    if (isLocalStorageAvailable()) {
      localStorage.setItem(key, value);
      console.log(`App.js: Saved to localStorage: ${key} = ${value}`);
    } else {
      memoryStorage[key] = value;
      console.log(`App.js: Saved to memoryStorage: ${key} = ${value}`);
    }
    return true;
  } catch (e) {
    console.error(`App.js: Error setting ${key} in storage:`, e);
    return false;
  }
};

const removeStorageItem = (key) => {
  try {
    if (isLocalStorageAvailable()) {
      localStorage.removeItem(key);
      console.log(`App.js: Removed from localStorage: ${key}`);
    } else {
      delete memoryStorage[key];
      console.log(`App.js: Removed from memoryStorage: ${key}`);
    }
    return true;
  } catch (e) {
    console.error(`App.js: Error removing ${key} from storage:`, e);
    return false;
  }
};

// ProtectedRoute Component
const ProtectedRoute = ({ children }) => {
  const token = Cookies.get('token'); // Retrieve token from cookie
  const lastUsed = getStorageItem('lastUsed');
  
  // Log authentication information for debugging
  console.log(`ProtectedRoute - Token exists: ${!!token}`);
  console.log(`ProtectedRoute - Auth method: ${lastUsed || 'unknown'}`);
  
  // If we have a token, check if it's valid
  if (token) {
    // For Discord auth, we just need to check if the token exists
    if (lastUsed === 'discord') {
      console.log('ProtectedRoute - Using Discord authentication');
      // Set a flag to indicate we're using Discord auth
      sessionStorage.setItem('currentAuth', 'discord');
      return children;
    }
    
    // For Google auth, we could do additional validation if needed
    console.log('ProtectedRoute - Using Google authentication');
    return children;
  }
  
  // If no token, redirect to login
  console.warn('No authentication token found, redirecting to login');
  return <Navigate to="/login" replace />;
};

function App() {
  // Initialize default values
  useEffect(() => {
    // Set default values if they don't exist
    if (getStorageItem('autoLogin') === null) {
      setStorageItem('autoLogin', 'true');
    }
  }, []);

  // Session restoration handler
  useEffect(() => {
    const restoreSession = async () => {
      try {
        // Don't restore session if we're on the login page
        if (window.location.pathname === '/login') {
          console.log('On login page, skipping session restoration');
          return;
        }
        
        // Check if manual login is required (after logout)
        const forceManualLogin = getStorageItem('forceManualLogin') === 'true';
        if (forceManualLogin) {
          console.log('Manual login required after logout, not restoring session');
          Cookies.remove('token'); // Ensure token is removed
          return;
        }
        
        // Check auto-login preference
        const shouldAutoLogin = getStorageItem('autoLogin') !== 'false'; // Default to true
        if (!shouldAutoLogin) {
          console.log('Auto-login disabled by user preference, not restoring session');
          // If auto-login is disabled, remove any existing token to force manual login
          Cookies.remove('token');
          return;
        }
        
        // Check which authentication method was last used
        const lastUsed = getStorageItem('lastUsed');
        console.log(`Last used authentication method: ${lastUsed || 'none'}`);
        
        // If user is authenticated in Firebase but no cookie token exists
        if (auth.currentUser && !Cookies.get('token')) {
          const idToken = await auth.currentUser.getIdToken(true);
          // Set with long expiration (90 days)
          Cookies.set('token', idToken, { expires: 90, path: '/' });
          console.log('Session restored successfully');
        }
        
        // If we have a token but no Firebase user, we might be using Discord auth
        // In this case, we should keep the token but not try to restore Firebase session
        if (!auth.currentUser && Cookies.get('token') && lastUsed === 'discord') {
          console.log('Maintaining Discord authentication session');
          // The token is already in cookies, so we just need to make sure it's not removed
        }
      } catch (error) {
        console.error('Failed to restore session:', error);
      }
    };
    
    // Try to restore on app start
    restoreSession();
    
    // Also try when visibility changes (tab becomes active)
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        restoreSession();
      }
    };
    
    document.addEventListener('visibilitychange', handleVisibilityChange);
    
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);
  
  return (
    <Router>
      <ProfileProvider>
        <ActionCardProvider>
          <Routes>
            {/* Redirect root to login page */}
            <Route path="/" element={<Navigate to="/login" replace />} />
            <Route path="/login" element={<LoginPage />} />
            
            {/* Discord auth routes */}
            <Route path="/auth/discord/callback" element={<DiscordCallback />} />
            
            {/* Protected routes */}
            <Route path="/home" element={<ProtectedRoute><LandingPage /></ProtectedRoute>} />
            <Route path="/profile" element={<ProtectedRoute><ProfilePage /></ProtectedRoute>} />
            <Route path="/upload" element={<ProtectedRoute><UploadPage /></ProtectedRoute>} />
            <Route path="/upscaler" element={<ProtectedRoute><UpscalerPage /></ProtectedRoute>} />
            <Route path="/restoration" element={<ProtectedRoute><ImageRestorationPage /></ProtectedRoute>} />
            <Route path="/success" element={<ProtectedRoute><SuccessPage /></ProtectedRoute>} />
          </Routes>
        </ActionCardProvider>
      </ProfileProvider>
    </Router>
  );
}

export default App;
