import React, { useState, useEffect } from "react";
import { Upload, Spin, message, Modal } from "antd";
import { useNavigate, useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import "../styles/UploadPage.css";
import GalleryItem from "../components/sharedCards/GalleryItem";
import ZoomControls from "../components/shared/ZoomControls";
import restorationHoverBackground from "../components/Assets/restoration-hover-background.png";
import upscalerHoverBackground from "../components/Assets/upscaler-hover-background.png";
import Lottie from "react-lottie";
import lottieAnimation from "../components/Assets/loading-animation.json";
import Hotjar from "@hotjar/browser";
import MobileWarningPopup from "./MobileWarningPopup"; // Import the MobileWarningPopup

// New imports for Loom video thumbnails
import restorationThumbnail from "../components/Assets/restoration-thumbnail.jpg";
import upscalerThumbnail from "../components/Assets/upscaler-thumbnail.jpg";

const { Dragger } = Upload;

const API_BASE = process.env.NODE_ENV === 'development'
  ? 'http://localhost:3001/api'
  : 'https://www.ek0go8g.vudoo.ai/api';

const UploadPage = () => {
  const [isUploading, setIsUploading] = useState(false);
  const [pageTitle, setPageTitle] = useState("");
  const [instructionText, setInstructionText] = useState("");
  const [galleryImages, setGalleryImages] = useState([]);
  const [loadingGallery, setLoadingGallery] = useState(true);
  const [tokensAvailable, setTokensAvailable] = useState(null);
  const [zoomLevel, setZoomLevel] = useState(100);
  const [hoverBackground, setHoverBackground] = useState("");
  const [loomVideoUrl, setLoomVideoUrl] = useState("");
  const [initialVideoUrl, setInitialVideoUrl] = useState("");
  const [videoThumbnail, setVideoThumbnail] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [showMobileWarning, setShowMobileWarning] = useState(false); // State to show the popup
  const navigate = useNavigate();
  const location = useLocation();

  // Lottie Animation Options
  const lottieOptions = {
    loop: true,
    autoplay: true,
    animationData: lottieAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  // Handle ESC key press
  useEffect(() => {
    const handleEscKey = (event) => {
      if (event.key === "Escape" && isModalVisible) {
        handleModalClose();
      }
    };

    window.addEventListener("keydown", handleEscKey);

    return () => {
      window.removeEventListener("keydown", handleEscKey);
    };
  }, [isModalVisible]);

  // Hotjar Integration
  useEffect(() => {
    Hotjar.init(process.env.REACT_APP_HOTJAR_ID, process.env.REACT_APP_HOTJAR_SNIPPET_VERSION);
  }, []);

  // Detect screen resolution
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 640) {
        setShowMobileWarning(true); // Show popup on mobile
      } else {
        setShowMobileWarning(false);
      }
    };

    handleResize(); // Check the screen size initially
    window.addEventListener("resize", handleResize); // Add event listener

    return () => {
      window.removeEventListener("resize", handleResize); // Clean up on unmount
    };
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const queryParams = new URLSearchParams(location.search);
      const type = queryParams.get("type");

      if (type === "restoration") {
        setPageTitle("Image Restoration");
        setInstructionText(
          "Upload your old or damaged photo. Our AI will work to repair imperfections and enhance overall quality. Results may vary as we refine this beta feature."
        );
        const restorationUrl =
          "https://www.loom.com/embed/a9c761012cf347b4a4d70f9d9fc79a93?sid=34239979-ad2f-451c-8814-c2e80c51e6bf";
        setInitialVideoUrl(restorationUrl);
        setHoverBackground(restorationHoverBackground);
        setVideoThumbnail(restorationThumbnail);
      } else if (type === "upscaler") {
        setPageTitle("Image Upscale");
        setInstructionText(
          "Upload your AI-generated image to enhance its resolution and quality. Our AI will process it to deliver pro-grade results ready for commercial use."
        );
        const upscalerUrl = "https://www.loom.com/embed/UPSCALER_VIDEO_ID";
        setInitialVideoUrl(upscalerUrl);
        setHoverBackground(upscalerHoverBackground);
        setVideoThumbnail(upscalerThumbnail);
      }

      await fetchUserProfile();
      await fetchGalleryImages(type);
      setLoadingGallery(false);
    };

    fetchData();
  }, [location]);

  const fetchUserProfile = async () => {
    const token = Cookies.get("token");
    if (!token) return;

    try {
      const response = await fetch(`${API_BASE}/profile`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error("Failed to fetch user profile");
      }

      const profileData = await response.json();
      setTokensAvailable(profileData.credits || 0);
    } catch (error) {
      console.error("Error fetching user profile:", error);
      message.error("Failed to load user profile.");
    }
  };

  const fetchGalleryImages = async (type) => {
    const token = Cookies.get("token");
    if (!token) return;

    const endpoint =
      type === "restoration"
        ? `${API_BASE}/imageRestoreGallery`
        : `${API_BASE}/imageUpscaleGallery`;

    try {
      const response = await fetch(endpoint, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error("Failed to fetch images");
      }

      const { images } = await response.json();

      const normalizedImages = (images || []).map((img) => ({
        ...img,
        uploadDate: img.dateProcessed || img.createdAt || "Unknown Date",
        toolUsed:
          type === "restoration" ? "Image Restoration" : "Image Upscaling",
      }));

      const imagesMap = new Map();
      normalizedImages.forEach((image) => {
        imagesMap.set(image.jobId, image);
      });

      const uniqueImages = Array.from(imagesMap.values());
      setGalleryImages(uniqueImages);
    } catch (error) {
      console.error("Error fetching images:", error);
    }
  };

  const handleFileUpload = async (info) => {
    const file = info.file.originFileObj || info.file;
    if (!file) return;

    setIsUploading(true);
    const formData = new FormData();
    formData.append("file", file);

    try {
      const token = Cookies.get("token");
      if (!token) {
        message.error("You must be logged in to upload images.");
        setIsUploading(false);
        return;
      }

      const response = await fetch(`${API_BASE}/upload`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });

      if (!response.ok) {
        throw new Error("Failed to upload image");
      }

      const data = await response.json();
      const cloudinaryUrl = data.cloudinaryUrl;

      sessionStorage.setItem("uploadedImageUrl", cloudinaryUrl);
      message.success("Image uploaded successfully!");

      const queryParams = new URLSearchParams(location.search);
      const type = queryParams.get("type");

      if (type === "restoration") {
        navigate("/restoration");
      } else if (type === "upscaler") {
        navigate("/upscaler");
      }
    } catch (error) {
      console.error("Error uploading image:", error);
      message.error("Upload failed!");
    } finally {
      setIsUploading(false);
    }
  };

  const handleZoomIn = () => {
    setZoomLevel((prevZoom) => Math.min(prevZoom + 10, 200));
  };

  const handleZoomOut = () => {
    setZoomLevel((prevZoom) => Math.max(prevZoom - 10, 50));
  };

  const handleModalOpen = () => {
    setIsModalVisible(true);
    // Add hideEmbedTopBar=true to prevent Loom's top bar from showing
    const videoUrlWithParams = `${initialVideoUrl}&hide_owner=true&hideEmbedTopBar=true`;
    setLoomVideoUrl(videoUrlWithParams);
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
    // Clear the video URL to stop playback
    setLoomVideoUrl("");
  };

  return (
    <div className="w-full min-h-screen bg-[#151515] flex flex-col items-center">
      {/* Home Button */}
      <div
        className="h-10 w-10 fixed top-4 left-4 z-50 cursor-pointer flex items-center justify-center bg-[#212121] border border-white/20 rounded-lg hover:bg-[#3a3a3a] transition-all duration-300 ease-in-out"
        onClick={() => navigate("/home")}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          fill="none"
          viewBox="0 0 20 20"
        >
          <path
            stroke="var(--white)"
            strokeWidth="1.5"
            d="M2.415 8.711a2.25 2.25 0 0 1 1.024-1.886l5.333-3.467a2.25 2.25 0 0 1 2.453 0l5.333 3.467a2.25 2.25 0 0 1 1.024 1.886v6.79c0 .69-.56 1.25-1.25 1.25h-3.667a.25.25 0 0 1-.25-.25v-3a1.75 1.75 0 0 0-1.75-1.75H9.332a1.75 1.75 0 0 0-1.75 1.75v3a.25.25 0 0 1-.25.25H3.665c-.69 0-1.25-.56-1.25-1.25V8.71Z"
          />
        </svg>
      </div>

      {/* Main Content */}
      <div
        className="flex flex-col justify-center items-center gap-10 w-full mt-10 p-5 sm:p-10 md:flex-row md:gap-20"
        style={{ height: 'auto' }}
      >
        {/* Left section: title and instructions */}
        <div className="w-full text-white flex flex-col gap-5 md:w-[400px]">
          <h1 className="text-3xl font-normal text-left m-0">{pageTitle}</h1>
          <p className="text-base opacity-70 mb-6 text-left">{instructionText}</p>
          <div
            className="w-full md:w-40 h-24 rounded-lg border border-white/30 flex justify-center items-center cursor-pointer bg-[#212121] relative"
            onClick={handleModalOpen}
          >
            {!isModalVisible && (
              <>
                <img
                  src={videoThumbnail}
                  alt="Video Thumbnail"
                  className="w-full h-full rounded-lg absolute inset-0 object-contain"
                />
                <div className="w-10 h-10 bg-white/30 rounded-full backdrop-blur-sm drop-shadow-lg flex justify-center items-center z-10">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="12"
                    fill="white"
                    viewBox="0 0 12 12"
                  >
                    <path fill="#fff" d="M2 12V0l10 6-10 6Z" />
                  </svg>
                </div>
              </>
            )}
          </div>
        </div>

        {/* Right section: Upload box */}
        <Dragger
          name="file"
          accept="image/*"
          beforeUpload={() => false}
          onChange={handleFileUpload}
          className="border border-[var(--color-w-30)] border-dashed upload-dragger w-full md:w-[600px] flex flex-col justify-items-stretch rounded-xl items-center bg-[var(--coal-800)] cursor-pointer relative group"
          showUploadList={false}
        >
          {/* Hover Background Image */}
          <div
            className="rounded-xl hover-background absolute inset-0 bg-cover bg-center opacity-0 group-hover:opacity-100 transition-opacity duration-300"
            style={{ backgroundImage: `url(${hoverBackground})` }}
          >
            <div className="absolute inset-0 bg-black bg-opacity-0"></div>
          </div>

          {/* Upload Content */}
          <div className="h-80 w-full flex flex-col justify-center items-center relative z-10">
            {!isUploading ? (
              <>
                <div className="w-14 h-14 border border-white/30 rounded-full mb-6 flex justify-center items-center group-hover:bg-white/10 transition-colors duration-300">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    stroke="white"
                    strokeWidth="1.5"
                    viewBox="0 0 24 24"
                  >
                    <path d="M12 3v9m0 9v-9m0 0h9m-9 0H3" />
                  </svg>
                </div>
                <p className="text-lg text-white mb-2 text-left">Click here to upload image</p>
                <p className="text-white text-sm opacity-50 text-left">or drag and drop here</p>
                {pageTitle === "Image Restoration" && (
                  <div className="absolute w-full bottom-0 flex justify-center items-center">
                    <div className="badge">Restorer is in beta, results may vary</div>
                  </div>
                )}
              </>
            ) : (
              <Lottie options={lottieOptions} height={78} width={78} />
            )}
          </div>
        </Dragger>
      </div>

      {/* Gallery Images */}
      <div
        className="flex flex-col gap-8 border-t border-white/20 mx-auto pt-8 px-4 w-full relative z-10"
      >
        <div className="flex justify-between items-start mb-4 relative z-10">
          <div className="flex flex-col gap-4 w-full">
            <div className="flex space-x-4">
              <div className="text-white px-1 pt-2 border-t-2 border-white">My Library</div>
            </div>
          </div>
        </div>

        <div
          className="grid gap-4 pt-4"
          style={{
            gridTemplateColumns: "repeat(auto-fit, minmax(160px, 1fr))",
          }}
        >
          {loadingGallery ? (
            <div className="text-white text-center col-span-full">Loading images...</div>
          ) : galleryImages.length > 0 ? (
            galleryImages.map((image) => (
              <GalleryItem key={image.id} image={image} />
            ))
          ) : (
            <div className="text-white text-center col-span-full">No images found. Start by uploading your first image!</div>
          )}
        </div>
      </div>

      {/* ZoomControls Component */}
      <ZoomControls
        zoomLevel={zoomLevel}
        onZoomIn={handleZoomIn}
        onZoomOut={handleZoomOut}
      />

      {/* Enhanced Modal for Loom Video */}
      <Modal
        visible={isModalVisible}
        onCancel={handleModalClose}
        footer={null}
        centered
        width="100%"
        maskClosable={true}
        keyboard={true}
        destroyOnClose={true}
        bodyStyle={{
          padding: 0,
          margin: 0,
          height: "50vh",
          backgroundColor: "#151515",
        }}
        closeIcon={
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="white"
            viewBox="0 0 24 24"
          >
            <path d="M18.364 5.636a1 1 0 0 0-1.414 0L12 10.586 7.05 5.636a1 1 0 0 0-1.415 1.415L10.586 12l-4.95 4.95a1 1 0 1 0 1.415 1.415L12 13.414l4.95 4.95a1 1 0 0 0 1.414-1.415L13.414 12l4.95-4.95a1 1 0 0 0 0-1.414Z" />
          </svg>
        }
      >
        <div className="video-container" style={{ width: "100%", height: "100%" }}>
          {loomVideoUrl && (
            <iframe
              src={loomVideoUrl}
              width="100%"
              height="100%"
              style={{ border: "none", margin: 0, padding: 0 }}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title="Loom Video"
            />
          )}
        </div>
      </Modal>

      {/* Mobile Warning Popup */}
      <MobileWarningPopup
        isVisible={showMobileWarning}
        closePopup={() => setShowMobileWarning(false)}
      />
    </div>
  );
};

export default UploadPage;
