// utils/stripe.js
import { loadStripe } from '@stripe/stripe-js';

let stripePromise;

export const getStripe = () => {
  // Debugging logs for clarity
  console.log("Stripe Publishable Key Loaded in Stripe.js (Environment):", process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY);
  
  // Option 1: Using environment variable directly
  if (!stripePromise && process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY) {
    stripePromise = loadStripe(`${process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY}`);
    console.log("Using environment variable key.");
  } else if (!stripePromise) {
    const hardcodedKey = 'pk_live_51Q2KDqRutoVG5e03JmjTzCuZoMIUTj6nFrEYOaWxaBC9J98pn4RiYuL6VgE4iKE0ehY76tppugpjtohsUAFe0pGS00A94Jf9cX'; // Replace with your hardcoded key
    console.warn("Environment variable not found. Falling back to hardcoded key.");
    stripePromise = loadStripe(hardcodedKey);
  }

  return stripePromise;
};
