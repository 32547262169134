import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import GalleryItem from "../components/sharedCards/GalleryItem";
import ImageModal from "../components/ImageModal";
import { learnItems } from "./learnItems"; // Import the learnItems array

const LibraryLearnSection = ({
  sessions,
  onSessionClick,
  onContinueWithProject,
  onExpandChange,
}) => {
  const [activeTab, setActiveTab] = useState("library");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedSession, setSelectedSession] = useState(null);
  const [isExpanded, setIsExpanded] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false); // State to track scroll
  const libraryRef = useRef(null);
  const navigate = useNavigate();

  const displayedLearnItems = learnItems.slice(0, 16); // Ensure at least 16 items are displayed

  useEffect(() => {
    const handleScroll = () => {
      if (libraryRef.current.scrollTop > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    const libraryElement = libraryRef.current;
    if (libraryElement) {
      libraryElement.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (libraryElement) {
        libraryElement.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  const handleLearnItemClick = (url) => {
    navigate(url);
  };

  const handleSessionClick = (session) => {
    setSelectedSession(session);
    setIsModalOpen(true);
    if (onSessionClick) {
      onSessionClick(session);
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedSession(null);
  };

  const handleContinueWithProject = (session) => {
    if (session) {
      sessionStorage.setItem("sessionData", JSON.stringify(session));
      navigate("/upscaler");
    }
  };

  // Create a shallow copy before sorting to avoid mutating props
  const sortedSessions = [...sessions].sort((a, b) => {
    const aLastVariantDate = new Date(
      a.variants[a.variants.length - 1]?.createdAt || 0
    );
    const bLastVariantDate = new Date(
      b.variants[b.variants.length - 1]?.createdAt || 0
    );
    return bLastVariantDate - aLastVariantDate;
  });

  const distributeSessions = (sessions) => {
    const columns = [[], [], [], []]; // 4 columns for the library tab
    sessions.forEach((session, index) => {
      columns[index % 4].push(session);
    });
    return columns;
  };

  const sessionColumns = distributeSessions(sortedSessions);

  const handleMouseEnter = () => {
    setIsExpanded(true);
    document.body.style.overflow = "hidden"; // Lock body scroll when expanded
    if (onExpandChange) onExpandChange(true);
  };

  const handleMouseLeave = () => {
    document.body.style.overflow = ""; // Restore body scroll when collapsed

    const libraryElement = libraryRef.current;
    if (libraryElement) {
      libraryElement.scrollTop = 0; // Reset scroll position to the top
    }

    // Add a slight delay before collapsing
    setTimeout(() => {
      setIsExpanded(false);
      if (onExpandChange) onExpandChange(false);
    }, 150); // Adjust the delay as needed (in milliseconds)
  };

  return (
    <div
      ref={libraryRef}
      className={`transition-all duration-300 ease-in-out fixed bottom-0 ${
        isExpanded ? "h-[97vh] " : "h-[10vh]  "
      } overflow-y-auto `}
      style={{
        width: "calc(100% - 160px)",
        left: "50%",
        transform: "translateX(-50%)" ,
        maxWidth: "1700px",
        paddingTop: isExpanded ? "0" : "15px",
        
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {/* Divider line */}
      <div className="pt-14"/>
      <div className="h-px bg-white/10 " />

      <div className="flex">
        <div className="w-[300px] text-white">
          <div className="flex flex-col gap-2 w-75">
            <div className="flex space-x-4">
              <button
                className={`px-1 pt-2 border-t-2 ${
                  activeTab === "library"
                    ? "border-white"
                    : "opacity-50 border-transparent"
                }`}
                onClick={() => setActiveTab("library")}
                aria-label="My Library Tab"
              >
                My Library
              </button>
              <button
                className={`px-1 pt-2 ${
                  activeTab === "learn" ? "opacity-100" : "opacity-50"
                }`}
                onClick={() => setActiveTab("learn")}
                aria-label="Learn Tab"
              >
                Learn
              </button>
            </div>
            <div className="opacity-50 text-sm">
              {activeTab === "library"
                ? "Manage and view your processed images"
                : "Explore articles and tutorials about image processing"}
            </div>
          </div>
        </div>

        <div className="flex-1 pt-4">
          {activeTab === "library" ? (
            sortedSessions.length > 0 ? (
              <div className="grid grid-cols-4 gap-3">
                {sessionColumns.map((column, columnIndex) => (
                  <div key={columnIndex} className="flex flex-col gap-3">
                    {column.map((session) => {
                        // Check if session is valid
                         if (!session || !session.variants || !Array.isArray(session.variants)) {
                              console.error(`Skipping session due to invalid session or missing variants:`, session);
                              return null;
                          }

                          // Debug log to check variant tool values
                          console.log(`Session ID: ${session.sessionId}, Variants:`, session.variants.map(v => ({ id: v.id, tool: v.tool, url: v.url })));

                          // Filter out sessions that have only the original image
                          const nonOriginalVariants = session.variants.filter(
                              (variant) => variant && variant.tool !== "original"
                          );

                         console.log(`Session ID: ${session.sessionId}, nonOriginalVariants:`, nonOriginalVariants.map(v => ({ id: v.id, tool: v.tool, url: v.url })));
                         //Skip session if there is no non-original variant
                         if(nonOriginalVariants.length === 0){
                            console.warn(`Session has no non-original variant. Skipping`,session)
                            return null
                         }

                         //Sort session for using latest URL
                         nonOriginalVariants.sort((a, b) => {
                            const aCreatedAt = new Date(a.createdAt || 0);
                            const bCreatedAt = new Date(b.createdAt || 0);
                            return bCreatedAt - aCreatedAt;
                        });
                          // Get the latest variant from the session
                        const latestVariant = nonOriginalVariants[0];
                        //More log information.
                        console.log(`Session ID: ${session.sessionId}, Using latestVariant:`, {
                            id: latestVariant?.id,
                            tool: latestVariant?.tool,
                            url: latestVariant?.url,
                        });

                          // URL Validation
                          if (!latestVariant?.url || typeof latestVariant?.url !== 'string' || !latestVariant?.url.startsWith('http')) {
                              console.error(`Invalid URL for Session ID: ${session.sessionId}, URL:`, latestVariant?.url);
                              return null; // Or return some placeholder GalleryItem
                          }
                      
                         return (
                             <GalleryItem
                                 key={session.sessionId}
                                 image={{
                                     id: session.sessionId,
                                     url: latestVariant.url,
                                     format: latestVariant.format,
                                     uploadDate: latestVariant.createdAt || session.uploadDate,
                                     tool: latestVariant.tool,
                                     settings: session.settings,
                                 }}
                                 onClick={() => handleSessionClick(session)}
                                 className="rounded-lg overflow-hidden hover:shadow-lg transition-shadow"
                             />
                         );
                    })}
                  </div>
                ))}
              </div>
            ) : (
              <div className="text-center text-gray-400">
                No upscaled image sessions found. Start by upscaling your first
                image!
              </div>
            )
          ) : (
            <div className="grid grid-cols-3 gap-4">
              {displayedLearnItems.map((item, index) => (
                <div
                  key={item.id}
                  className={`bg-[#2C2C2C] rounded-lg cursor-pointer hover:bg-[#3C3C3C] transition-colors duration-500 ${
                    index >= 6 ? "opacity-0" : ""
                  }`}
                  onClick={() => handleLearnItemClick(item.url)}
                  role="button"
                  tabIndex={0}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      handleLearnItemClick(item.url);
                    }
                  }}
                  aria-label={`Navigate to ${item.title}`}
                >
                  <img
                    src={item.thumbnailUrl || "/placeholder.svg"}
                    alt={item.title}
                    className="w-full h-40 object-cover rounded-t-lg"
                  />
                  <div className="p-4">
                    <div className="text-white text-lg font-semibold mb-2">
                      {item.title}
                    </div>
                    <div className="text-white opacity-50">{item.type}</div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>

      {/* Image Modal */}
      <ImageModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        session={selectedSession}
        onContinue={handleContinueWithProject}
      />
    </div>
  );
};

export default LibraryLearnSection;