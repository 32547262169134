/**
 * Application Configuration
 * 
 * Production endpoints hardcoded for deployment
 */

// API configurations
const API_CONFIG = {
  // Base URL for API requests
  apiBaseUrl: 'https://www.ek0go8g.vudoo.ai/api',
  
  // Upload endpoint
  uploadUrl: 'https://www.ek0go8g.vudoo.ai/api/upload',
  
  // Cloudinary endpoints
  cloudinary: {
    transformUrl: 'https://www.ek0go8g.vudoo.ai/api/cloudinary/transform',
    responsiveUrl: 'https://www.ek0go8g.vudoo.ai/api/cloudinary/responsive',
  }
};

// Auth related configurations
const AUTH_CONFIG = {
  tokenStorageKey: 'token',
  profileStorageKey: 'userProfile'
};

// Feature flags
const FEATURES = {
  useHybridStorage: true, // Enable S3 + Cloudinary hybrid approach
  useResponsiveImages: true,
};

// Export the configurations
export { API_CONFIG, AUTH_CONFIG, FEATURES }; 