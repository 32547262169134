/**
 * Utilities for handling image caching in the application
 * Improves performance and reduces load on S3 buckets
 */

// Cache duration constants (in seconds)
export const CACHE_DURATIONS = {
  SHORT: 60 * 5, // 5 minutes
  MEDIUM: 60 * 60, // 1 hour
  LONG: 60 * 60 * 24, // 1 day
  VERY_LONG: 60 * 60 * 24 * 7, // 1 week
  PERMANENT: 60 * 60 * 24 * 30, // 30 days
};

// In-memory cache for S3 image URLs
const urlCache = new Map();

/**
 * Safely create a URL object with error handling
 * @param {string} url - URL string to parse
 * @returns {URL|null} - URL object or null if invalid
 */
const safeCreateURL = (url) => {
  if (!url) return null;
  try {
    return new URL(url);
  } catch (error) {
    console.warn(`Invalid URL format: ${url}`);
    return null;
  }
};

/**
 * Adds cache control parameters to S3 URLs
 * @param {string} s3Url - The original S3 URL
 * @param {number} maxAge - Cache duration in seconds
 * @returns {string} - S3 URL with cache control parameters
 */
export const addCacheControlToS3Url = (s3Url, maxAge = CACHE_DURATIONS.LONG) => {
  if (!s3Url) return s3Url;
  
  try {
    const url = safeCreateURL(s3Url);
    if (!url) return s3Url;
    
    // Check if it's an S3 URL
    const isS3Url = 
      s3Url.includes('your-objectstorage.com') || 
      s3Url.includes('hetzner') || 
      s3Url.includes('s3.');
    
    if (!isS3Url) return s3Url;
    
    // Add cache control parameters
    url.searchParams.set('response-cache-control', `public, max-age=${maxAge}`);
    
    return url.toString();
  } catch (error) {
    console.error('Error adding cache control to S3 URL:', error);
    return s3Url;
  }
};

/**
 * Gets a cached image URL or caches it if not already cached
 * @param {string} originalUrl - The original image URL
 * @param {number} cacheDuration - How long to cache the URL (in seconds)
 * @returns {string} - The cached or original URL
 */
export const getCachedImageUrl = (originalUrl, cacheDuration = CACHE_DURATIONS.LONG) => {
  if (!originalUrl) return '';
  
  try {
    // Check if URL is already in our cache
    if (urlCache.has(originalUrl)) {
      const cachedEntry = urlCache.get(originalUrl);
      if (Date.now() < cachedEntry.expiry) {
        return cachedEntry.optimizedUrl;
      }
    }
    
    // Attempt to add cache control headers if it's an S3 URL
    let optimizedUrl = addCacheControlToS3Url(originalUrl, cacheDuration);
    
    // Store in cache
    urlCache.set(originalUrl, {
      optimizedUrl,
      expiry: Date.now() + (cacheDuration * 1000)
    });
    
    return optimizedUrl;
  } catch (error) {
    console.error('Error in getCachedImageUrl:', error);
    return originalUrl; // Fallback to original URL
  }
};

/**
 * Prefetches an image to browser cache
 * @param {string} imageUrl - URL of the image to prefetch
 * @returns {Promise<boolean>} - Success status
 */
export const prefetchImage = async (imageUrl) => {
  if (!imageUrl) return false;
  
  try {
    // Get cached URL if available
    const cachedUrl = getCachedImageUrl(imageUrl);
    
    // Simple preloading approach that works in all browsers
    const img = new Image();
    img.src = cachedUrl;
    return true;
  } catch (error) {
    console.error('Error prefetching image:', error);
    return false;
  }
};

/**
 * Bulk prefetch multiple images (e.g., for gallery views)
 * @param {Array<string>} imageUrls - Array of image URLs to prefetch
 * @returns {Promise<Array<boolean>>} - Array of success statuses
 */
export const prefetchImages = async (imageUrls) => {
  if (!imageUrls || !Array.isArray(imageUrls)) return [];
  
  try {
    // Filter out invalid URLs
    const validUrls = imageUrls.filter(url => typeof url === 'string' && url);
    
    // Only prefetch a reasonable number of images (max 5)
    const limitedUrls = validUrls.slice(0, 5);
    
    return Promise.all(
      limitedUrls.map(url => prefetchImage(url).catch(() => false))
    );
  } catch (error) {
    console.error('Error in prefetchImages:', error);
    return [];
  }
};

/**
 * Clear the in-memory URL cache
 */
export const clearUrlCache = () => {
  try {
    urlCache.clear();
    console.log('Image URL cache cleared');
  } catch (error) {
    console.error('Error clearing URL cache:', error);
  }
}; 