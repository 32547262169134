// src/components/ActionCardsData.js

// Import the images needed for the cards
import generalUpscaleHover from "./Assets/general-upscale-hover.png";
import productUpscaleHover from "./Assets/product-upscale-hover.png";
import highResPortraitHover from "./Assets/high-res-portrait-hover.png";
import restorationHover from "./Assets/restoration-hover.png";

import uploadUpscaleHover from "./Assets/upscaler-hover-background.png";
import uploadRestorationHover from "./Assets/restoration-hover-background.png";



// Import the thumbnails for the cards
import generalUpscaleThumbnail from "./Assets/upscaler-thumbnail.jpg";
import lowResPortraitThumbnail from "./Assets/upscaler-thumbnail.jpg";
import highResPortraitThumbnail from "./Assets/upscaler-thumbnail.jpg";
import restorationThumbnail from "./Assets/restoration-thumbnail.jpg";

// Define the action cards data
export const actionCards = [
    {
        id: 1,
        title: "General Upscale",
        description: "Enhance image resolution and quality for sharper, more detailed visuals. Perfect for all AI-generated artwork.",
        buttonText: "Start Upscaling",
        hoverBackgroundImage: generalUpscaleHover,
        thumbnailImage: generalUpscaleThumbnail,
        loomVideoUrl: "https://www.loom.com/embed/61a065ccf2804e6c8f882377bee21ac9?sid=7b8888b5-2cf5-4bac-8161-aeaca7d8b408",
        betaBadge: false,
        newBadge: false,
        comingSoon: false,
        type: "upscaler",
        endpoint: "upscale", // Endpoint used for this action card
        uploadHoverImage: uploadUpscaleHover, // New property for the hover image
    },
    {
        id: 2,
        title: "High Res Portrait",
        description: "Bring AI portraits to realism with pro-grade detail refinement and lifelike expressions, eyes, faces and skin.",
        buttonText: "Refine Portrait",
        hoverBackgroundImage: highResPortraitHover,
        thumbnailImage: highResPortraitThumbnail,
        loomVideoUrl: "https://www.loom.com/embed/61a065ccf2804e6c8f882377bee21ac9?sid=7b8888b5-2cf5-4bac-8161-aeaca7d8b408",
        betaBadge: false,
        newBadge: true,
        comingSoon: false,
        type: "upscaler-highres",
        endpoint: "upscaler-highres", // Endpoint used for this action card
        uploadHoverImage: uploadUpscaleHover, // New property for the hover image
    },
    {
        id: 3,
        title: "Product Photography",
        description: "Enhance product photos with sharp details and true-to-life textures. Perfect for e-commerce and marketing images.",
        buttonText: "Enhance Portrait",
        hoverBackgroundImage: productUpscaleHover,
        thumbnailImage: lowResPortraitThumbnail,
        loomVideoUrl: "https://www.loom.com/embed/61a065ccf2804e6c8f882377bee21ac9?sid=7b8888b5-2cf5-4bac-8161-aeaca7d8b408",
        betaBadge: false,
        newBadge: true,
        comingSoon: false,
        type: "upscaler-product",
        endpoint: "upscaler-product", // Endpoint used for this action card
        uploadHoverImage: uploadUpscaleHover, // New property for the hover image
    },
    {
        id: 4,
        title: "Restoration",
        description: "Restore old or damaged photos to pristine condition with AI precision. Give timeless memories new life.",
        buttonText: "Restore Photo",
        hoverBackgroundImage: restorationHover,
        thumbnailImage: restorationThumbnail,
        loomVideoUrl: "https://www.loom.com/embed/a9c761012cf347b4a4d70f9d9fc79a93?sid=34239979-ad2f-451c-8814-c2e80c51e6bf",
        newBadge: false,
        betaBadge: true,
        comingSoon: false,
        type: "restoration",
        endpoint: "restore", // Endpoint used for this action card
        uploadHoverImage: uploadRestorationHover, // New property for the hover image
    },
];

export default actionCards;
