// frontend/src/pages/LoginPage.jsx
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { 
  signInWithPopup, 
  GoogleAuthProvider, 
  onIdTokenChanged,
  getRedirectResult,
  signInWithRedirect,
  signInWithCustomToken
} from 'firebase/auth';
import { auth, updatePersistence } from '../firebase';
import Cookies from 'js-cookie';
import Lottie from 'react-lottie';
import animationData from '../components/Assets/vudoo-logo-animation.json';
import background from '../components/Assets/background.png';
import Hotjar from '@hotjar/browser';
import MobileWarningPopup from './MobileWarningPopup'; // Import the MobileWarningPopup

// Helper functions for localStorage with fallbacks
const isLocalStorageAvailable = () => {
  try {
    const testKey = '__test__';
    localStorage.setItem(testKey, testKey);
    localStorage.removeItem(testKey);
    console.log('localStorage is available');
    return true;
  } catch (e) {
    console.error('localStorage is not available:', e);
    return false;
  }
};

// Memory fallback if localStorage isn't available
const memoryStorage = {};

const getStorageItem = (key, defaultValue = null) => {
  try {
    if (isLocalStorageAvailable()) {
      const value = localStorage.getItem(key);
      console.log(`Retrieved from localStorage: ${key} = ${value}`);
      return value;
    } else {
      console.log(`Retrieved from memoryStorage: ${key} = ${memoryStorage[key]}`);
      return memoryStorage[key] || defaultValue;
    }
  } catch (e) {
    console.error(`Error getting ${key} from storage:`, e);
    return defaultValue;
  }
};

const setStorageItem = (key, value) => {
  try {
    if (isLocalStorageAvailable()) {
      localStorage.setItem(key, value);
      console.log(`Saved to localStorage: ${key} = ${value}`);
    } else {
      memoryStorage[key] = value;
      console.log(`Saved to memoryStorage: ${key} = ${value}`);
    }
    return true;
  } catch (e) {
    console.error(`Error setting ${key} in storage:`, e);
    return false;
  }
};

const removeStorageItem = (key) => {
  try {
    if (isLocalStorageAvailable()) {
      localStorage.removeItem(key);
      console.log(`Removed from localStorage: ${key}`);
    } else {
      delete memoryStorage[key];
      console.log(`Removed from memoryStorage: ${key}`);
    }
    return true;
  } catch (e) {
    console.error(`Error removing ${key} from storage:`, e);
    return false;
  }
};

export default function LoginPage() {
  const [loading, setLoading] = useState(true); // Start with loading true
  const [redirecting, setRedirecting] = useState(false); // New state to track when we're redirecting
  const [error, setError] = useState('');
  const [lastUsed, setLastUsed] = useState('');
  const [showMobileWarning, setShowMobileWarning] = useState(false); // State to show the popup
  const [autoLoginAttempted, setAutoLoginAttempted] = useState(false);
  const [storageAvailable, setStorageAvailable] = useState(true);
  const navigate = useNavigate();
  const location = useLocation(); // Add this to access URL parameters
  const googleProvider = new GoogleAuthProvider();

  // Check if localStorage is available
  useEffect(() => {
    const available = isLocalStorageAvailable();
    setStorageAvailable(available);
    
    // Set default values if storage is available but values aren't set
    if (available) {
      if (localStorage.getItem('autoLogin') === null) {
        console.log('Setting default autoLogin value to true');
        localStorage.setItem('autoLogin', 'true');
      }
    }
  }, []);

  // Clear forceManualLogin flag if there's no active session
  // This prevents getting stuck in a state where you can't login
  useEffect(() => {
    const checkAndClearFlags = async () => {
      try {
        // If there's no current user but forceManualLogin is set, clear it
        if (!auth.currentUser && getStorageItem('forceManualLogin') === 'true') {
          console.log('No active session but forceManualLogin is set. Clearing flag.');
          removeStorageItem('forceManualLogin');
        }
      } catch (error) {
        console.error('Error checking auth state:', error);
      }
    };
    
    checkAndClearFlags();
  }, []);

  // Hotjar Integration
  useEffect(() => {
    Hotjar.init(process.env.REACT_APP_HOTJAR_ID, process.env.REACT_APP_HOTJAR_SNIPPET_VERSION);
  }, []);

  // Detect screen resolution
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setShowMobileWarning(true); // Show popup on mobile
      } else {
        setShowMobileWarning(false);
      }
    };

    handleResize(); // Check the screen size initially
    window.addEventListener('resize', handleResize); // Add event listener

    return () => {
      window.removeEventListener('resize', handleResize); // Clean up on unmount
    };
  }, []);

  // Retrieve last used method from local storage
  useEffect(() => {
    const savedLastUsed = getStorageItem('lastUsed');
    if (savedLastUsed) setLastUsed(savedLastUsed);
  }, []);

  // Auto-login on component mount
  useEffect(() => {
    const attemptAutoLogin = async () => {
      try {
        setLoading(true);
        
        // Check if manual login is required (after logout)
        const forceManualLogin = getStorageItem('forceManualLogin') === 'true';
        
        if (forceManualLogin) {
          console.log('Manual login required after logout');
          setLoading(false);
          setAutoLoginAttempted(true);
          return; 
        }
        
        // Check auto-login preference
        const shouldAutoLogin = getStorageItem('autoLogin') !== 'false'; // Default to true
        
        // If auto-login is disabled, don't attempt auto-login and clear any existing session
        if (!shouldAutoLogin) {
          console.log('Auto-login disabled by user preference');
          // Don't use existing session when auto-login is disabled
          if (auth.currentUser) {
            console.log('Ignoring existing session due to auto-login preference');
          }
          setLoading(false);
          setAutoLoginAttempted(true);
          return;
        }
        
        // Show the login page first by setting loading to false
        setLoading(false);
        
        // Add a deliberate delay before auto-login to briefly show the login page
        // This allows users to see the login screen even with active session
        await new Promise(resolve => setTimeout(resolve, 2000)); // 2-second delay
        
        // Now we're starting the redirect process
        setRedirecting(true);
        
        // Check if a redirect result is available (from a previous redirect)
        const result = await getRedirectResult(auth);
        
        if (result?.user) {
          // User just returned from a redirect login flow
          await handleSuccessfulLogin(result.user);
          return;
        }
        
        // Check if user is already signed in
        const user = auth.currentUser;
        
        if (user) {
          // User is already signed in
          await handleSuccessfulLogin(user);
          return;
        }
        
        const lastLoginMethod = getStorageItem('lastUsed');
        
        if (lastLoginMethod === 'google') {
          // Attempt auto-login with Google
          await signInWithRedirect(auth, googleProvider);
          return; // This will redirect, so we'll return to this component after auth
        } else if (lastLoginMethod === 'discord') {
          // Attempt auto-login with Discord
          handleDiscordLogin();
          return;
        }
        
        // If we get here, no auto-login was possible
        setRedirecting(false);
        setAutoLoginAttempted(true);
        setLoading(false);
      } catch (error) {
        console.error('Auto-login error:', error);
        // Only show error if we're still on the login page
        if (location.pathname.includes('/login')) {
          setError('Failed to automatically sign you in. Please log in manually.');
        }
        setRedirecting(false);
        setAutoLoginAttempted(true);
        setLoading(false);
      }
    };

    // Only attempt if not already attempted
    if (!autoLoginAttempted) {
      attemptAutoLogin();
    }
  }, [autoLoginAttempted, navigate, location.pathname]);

  // Set up token refresh listener on component mount
  useEffect(() => {
    const unsubscribe = onIdTokenChanged(auth, async (user) => {
      if (user) {
        const idToken = await user.getIdToken(true); // Fetch the refreshed token
        Cookies.set('token', idToken, { expires: 90, path: '/' }); // Update for 90 days
      } else {
        Cookies.remove('token'); // Remove token if user logs out or session expires
      }
    });

    return () => unsubscribe(); // Cleanup listener on component unmount
  }, []);

  // Visibility change handler to refresh token
  useEffect(() => {
    const handleVisibilityChange = async () => {
      if (document.visibilityState === 'visible') {
        const user = auth.currentUser;
        if (user) {
          const idToken = await user.getIdToken(true); // Force refresh the token
          Cookies.set('token', idToken, { expires: 90, path: '/' }); // Use 90-day expiration
        }
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  // Background refresh of the token every 30 minutes
  useEffect(() => {
    const refreshTokenInterval = setInterval(async () => {
      const user = auth.currentUser;
      if (user) {
        const idToken = await user.getIdToken(true); // Force a refresh of the ID token
        Cookies.set('token', idToken, { expires: 90, path: '/' }); // Use 90-day expiration
      }
    }, 30 * 60 * 1000); // Refresh every 30 minutes

    return () => clearInterval(refreshTokenInterval); // Cleanup interval on unmount
  }, []);

  // Add a check for failed silent Discord auth (when prompt=none fails)
  useEffect(() => {
    const checkFailedDiscordAuth = () => {
      // Check if we were redirected back from Discord with an error
      const urlParams = new URLSearchParams(window.location.search);
      const error = urlParams.get('error');
      
      if (error === 'access_denied' && getStorageItem('lastUsed') === 'discord') {
        // This happens when prompt=none fails (user needs to authorize)
        // We'll try again without the prompt=none parameter
        console.log('Silent Discord auth failed, retrying with explicit authorization');
        handleDiscordLogin();
      } else if (error) {
        // Only set error state if there's an actual error parameter and we're not handling it
        console.error('Authentication error:', error);
        setError(`Authentication error: ${error}`);
        setRedirecting(false);
        setLoading(false);
      }
    };
    
    checkFailedDiscordAuth();
  }, []);

  // Add a new effect to handle Discord callback
  useEffect(() => {
    const handleDiscordCallback = async () => {
      // Check if we're on the Discord callback route
      if (location.pathname === '/auth/discord/callback') {
        setRedirecting(true);
        
        try {
          const params = new URLSearchParams(location.search);
          const token = params.get('token');
          const userId = params.get('userId');
          const isNewUser = params.get('isNewUser') === 'true';
          
          if (!token) {
            throw new Error('No authentication token provided');
          }
          
          console.log('Processing Discord authentication callback');
          
          // Sign in with the custom token from Discord auth
          await signInWithCustomToken(auth, token);
          
          // Get the ID token to use with our backend
          const user = auth.currentUser;
          if (!user) throw new Error('Failed to authenticate with Firebase');
          
          const idToken = await user.getIdToken();
          
          // Verify with backend
          const response = await fetch('https://www.ek0go8g.vudoo.ai/api/auth', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ idToken, type: 'discord' }),
          });
          
          if (!response.ok) {
            throw new Error('Failed to authenticate with server');
          }
          
          // Set long expiration (90 days)
          Cookies.set('token', idToken, { expires: 90, path: '/' });
          
          // Clear the force manual login flag
          removeStorageItem('forceManualLogin');
          
          // Remember the login method
          setStorageItem('lastUsed', 'discord');
          setLastUsed('discord');
          
          // Navigate to homepage
          navigate('/home');
        } catch (error) {
          console.error('Discord authentication error:', error);
          // Only show error if we're still on the login page
          if (location.pathname.includes('/login') || location.pathname.includes('/auth/discord')) {
            setError('Discord authentication failed. Please try again.');
            setRedirecting(false);
            setLoading(false);
          }
        }
      }
    };
    
    handleDiscordCallback();
  }, [location, navigate]);

  // Handle successful login
  const handleSuccessfulLogin = async (user, provider = 'google') => {
    try {
      setRedirecting(true); // Ensure we're in redirecting state
      const idToken = await user.getIdToken();

      // Verify with backend
      const response = await fetch('https://www.ek0go8g.vudoo.ai/api/auth', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ idToken, type: provider }),
      });

      if (!response.ok) {
        throw new Error('Failed to authenticate with server');
      }

      // Set long expiration (90 days)
      Cookies.set('token', idToken, { expires: 90, path: '/' });
      
      // Clear the force manual login flag
      removeStorageItem('forceManualLogin');
      
      // Remember the login method
      setStorageItem('lastUsed', provider);
      setLastUsed(provider);

      // Navigate to homepage
      setTimeout(() => navigate('/home'), 2000);
    } catch (error) {
      console.error('Authentication error:', error);
      // Only show error if we're still on the login page
      if (location.pathname.includes('/login')) {
        setError('Authentication failed. Please try again.');
        setRedirecting(false);
        setLoading(false);
      }
    }
  };

  // Handle Discord login
  const handleDiscordLogin = async () => {
    setError('');
    setLoading(true);
    setRedirecting(true);

    try {
      // Clear forceManualLogin when attempting manual login
      removeStorageItem('forceManualLogin');
      console.log('Discord login attempt - cleared forceManualLogin flag');
      
      // Remember the login method before redirecting
      // This is critical for maintaining the Discord auth session
      setStorageItem('lastUsed', 'discord');
      setLastUsed('discord');
      console.log('Set authentication method to Discord');
      
      // Determine which API endpoint to use based on environment
      const apiBaseUrl = window.location.hostname === 'localhost' 
        ? 'http://localhost:3001' 
        : 'https://www.ek0go8g.vudoo.ai';
      
      // Get Discord auth URL from backend
      const response = await fetch(`${apiBaseUrl}/api/auth/discord/url`);
      
      if (!response.ok) {
        throw new Error('Failed to get Discord authentication URL');
      }
      
      const data = await response.json();
      
      // Store timestamp of Discord login attempt to detect and handle failed silent auth
      setStorageItem('discordAuthAttempt', Date.now().toString());
      
      // Redirect to Discord OAuth
      window.location.href = data.url;
    } catch (error) {
      console.error('Discord Sign-In Error:', error);
      // Only show error if we're still on the login page
      if (location.pathname.includes('/login')) {
        setError('An error occurred during Discord Sign-In. Please try again.');
        setRedirecting(false);
        setLoading(false);
      }
    }
  };

  const handleGoogleLogin = async () => {
    setError('');
    setLoading(true);
    setRedirecting(true); // Set redirecting state for manual login too

    try {
      // Always clear forceManualLogin when attempting manual login
      removeStorageItem('forceManualLogin');
      console.log('Manual login attempt - cleared forceManualLogin flag');
      
      const result = await signInWithPopup(auth, googleProvider);
      await handleSuccessfulLogin(result.user);
    } catch (error) {
      console.error('Google Sign-In Error:', error);
      
      // Only show error if we're still on the login page
      if (location.pathname.includes('/login')) {
        // More descriptive error message based on the error type
        if (error.code === 'auth/popup-closed-by-user') {
          setError('Sign-in popup was closed. Please try again.');
        } else if (error.code === 'auth/network-request-failed') {
          setError('Network error. Please check your connection and try again.');
        } else {
          setError('An error occurred during Google Sign-In. Please try again.');
        }
        
        setRedirecting(false);
        setLoading(false);
      }
    }
  };

  // Show redirecting screen only when we're actually redirecting
  if (redirecting) {
    return (
      <div className="min-h-screen flex flex-col items-center justify-center bg-[#0A0A0A] fixed inset-0 z-50" 
           style={{ 
             backgroundImage: `url(${background})`, 
             backgroundSize: 'cover', 
             backdropFilter: 'blur(8px)',
           }}>
        {/* This overlay will hide any error messages */}
        <div className="absolute inset-0 bg-black bg-opacity-20"></div>
        
        <div className="flex flex-col items-center justify-center space-y-6 relative z-50">
          <div className="flex items-center justify-center">
            <Lottie
              options={{ 
                loop: true, 
                autoplay: true, 
                animationData, 
                rendererSettings: { 
                  preserveAspectRatio: 'xMidYMid slice',
                } 
              }}
              height={16}
              width="auto"
              isClickToPauseDisabled={true}
            />
          </div>
          <h2 className="text-2xl font-serif text-white animate-pulse text-center">
          Logging you in...
          </h2>
        </div>
      </div>
    );
  }

  // Show loading spinner only during initial loading, not during redirect
  if (loading && !autoLoginAttempted) {
    return (
      <div className="min-h-screen flex flex-col items-center justify-center bg-[#0A0A0A]" 
           style={{ backgroundImage: `url(${background})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}>
        <header className="absolute top-4 left-4 flex items-center cursor-pointer" onClick={() => navigate('/')}>
          <Lottie
            options={{ loop: true, autoplay: true, animationData, rendererSettings: { preserveAspectRatio: 'xMidYMid slice' } }}
            height={16}
            width="auto"
          />
        </header>
        
        <div className="text-center">
          <div className="w-16 h-16 border-t-2 border-b-2 border-white rounded-full animate-spin"></div>
          <p className="text-white mt-4">Loading...</p>
        </div>
      </div>
    );
  }

  return (
    <div
      className="min-h-screen flex items-center justify-center bg-[#0A0A0A] bg-cover bg-center"
      style={{ backgroundImage: `url(${background})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}
    >
      <header className="absolute top-4 left-4 flex items-center cursor-pointer" onClick={() => navigate('/')}>
        <Lottie
          options={{ loop: true, autoplay: true, animationData, rendererSettings: { preserveAspectRatio: 'xMidYMid slice' } }}
          height={16}
          width="auto"
        />
      </header>

      <div className="w-full max-w-[500px]">
        <div className="bg-[#151515] rounded-lg p-8 shadow-xl transition-all duration-300">
          <h2 className="text-2xl font-serif text-white mb-4">Sign in</h2>
          <p className="text-gray-400 mb-8">
            Welcome to Vudoo!{' '}
            <span className="text-gray-400 mb-8">
              Continue with Google or Discord
            </span>
          </p>
          <div className="space-y-3 mb-4">
            <button
              onClick={handleGoogleLogin}
              disabled={loading}
              className="h-[56px] w-full bg-[#d5d5d5] rounded-md border border-white/20 flex items-center justify-center relative transition-all duration-300 ease-in-out hover:bg-opacity-90 hover:bg-[#d5d5d5]"
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 23 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="mr-2"
              >
                <path
                  opacity="0.5"
                  d="M10.95 9.625V12.375C10.95 12.8304 11.319 13.2 11.775 13.2H17.7199C17.4455 13.9788 17.0292 14.6839 16.5072 15.2928L19.8254 18.1687C21.4875 16.2409 22.5 13.739 22.5 11C22.5 10.5446 22.4648 10.0716 22.3889 9.5139C22.3334 9.10525 21.9841 8.8 21.5716 8.8H11.775C11.319 8.8 10.95 9.1696 10.95 9.625ZM5.2366 13.0686C5.021 12.4173 4.9 11.7227 4.9 11C4.9 10.2773 5.021 9.58265 5.2366 8.93145L1.63025 6.17375C0.9136 7.6329 0.5 9.26695 0.5 11C0.5 12.7331 0.9136 14.3671 1.6308 15.8263L5.2366 13.0686ZM15.2758 16.4093C14.1983 17.1622 12.8926 17.6 11.5 17.6C9.1834 17.6 7.14565 16.3977 5.96755 14.5871L2.46845 17.2629C4.45725 20.1218 7.7611 22 11.5 22C14.2324 22 16.7294 20.9924 18.6549 19.338L15.2758 16.4093ZM18.9333 2.91335C16.8972 1.03455 14.2572 0 11.5 0C7.7611 0 4.45725 1.87825 2.46845 4.7377L5.96755 7.41345C7.14565 5.6023 9.1834 4.4 11.5 4.4C13.1192 4.4 14.6768 4.99455 15.8857 6.07365C16.2119 6.36515 16.7096 6.35085 17.0187 6.0412L18.9574 4.103C19.1164 3.9446 19.2033 3.72735 19.1989 3.50295C19.1945 3.27855 19.0982 3.0657 18.9333 2.91335Z"
                  fill="black"
                />
              </svg>
              <div className="text-black text-base font-medium">Google</div>
              {lastUsed === 'google' && (
                <div className="absolute right-3 text-xs text-gray-700">Last used</div>
              )}
            </button>
            
            {/* Discord Login Button */}
            <button
              onClick={handleDiscordLogin}
              disabled={loading}
              className="h-[56px] w-full bg-[#5865F2] rounded-md border border-white/20 flex items-center justify-center relative transition-all duration-300 ease-in-out hover:bg-opacity-90 hover:bg-[#4752C4]"
            >
              <svg 
                width="20" 
                height="20" 
                viewBox="0 0 71 55" 
                fill="none" 
                xmlns="http://www.w3.org/2000/svg"
                className="mr-2"
              >
                <path d="M60.1045 4.8978C55.5792 2.8214 50.7265 1.2916 45.6527 0.41542C45.5603 0.39851 45.468 0.440769 45.4204 0.525289C44.7963 1.6353 44.105 3.0834 43.6209 4.2216C38.1637 3.4046 32.7345 3.4046 27.3892 4.2216C26.905 3.0581 26.1886 1.6353 25.5617 0.525289C25.5141 0.443589 25.4218 0.40133 25.3294 0.41542C20.2584 1.2888 15.4057 2.8186 10.8776 4.8978C10.8384 4.9147 10.8048 4.9429 10.7825 4.9795C1.57795 18.7309 -0.943561 32.1443 0.293408 45.3914C0.299005 45.4562 0.335386 45.5182 0.385761 45.5576C6.45866 50.0174 12.3413 52.7249 18.1147 54.5195C18.2071 54.5477 18.305 54.5139 18.3638 54.4378C19.7295 52.5728 20.9469 50.6063 21.9907 48.5383C22.0523 48.4172 21.9935 48.2735 21.8676 48.2256C19.9366 47.4931 18.0979 46.6 16.3292 45.5858C16.1893 45.5041 16.1781 45.304 16.3068 45.2082C16.679 44.9293 17.0513 44.6391 17.4067 44.3461C17.471 44.2926 17.5606 44.2813 17.6362 44.3151C29.2558 49.6202 41.8354 49.6202 53.3179 44.3151C53.3935 44.2785 53.4831 44.2898 53.5502 44.3433C53.9057 44.6363 54.2779 44.9293 54.6529 45.2082C54.7816 45.304 54.7732 45.5041 54.6333 45.5858C52.8646 46.6197 51.0259 47.4931 49.0921 48.2228C48.9662 48.2707 48.9102 48.4172 48.9718 48.5383C50.038 50.6034 51.2554 52.5699 52.5959 54.435C52.6519 54.5139 52.7526 54.5477 52.845 54.5195C58.6464 52.7249 64.529 50.0174 70.6019 45.5576C70.6551 45.5182 70.6887 45.459 70.6943 45.3942C72.1747 30.0791 68.2147 16.7757 60.1968 4.9823C60.1772 4.9429 60.1437 4.9147 60.1045 4.8978ZM23.7259 37.3253C20.2276 37.3253 17.3451 34.1136 17.3451 30.1693C17.3451 26.225 20.1717 23.0133 23.7259 23.0133C27.308 23.0133 30.1626 26.2532 30.1066 30.1693C30.1066 34.1136 27.28 37.3253 23.7259 37.3253ZM47.3178 37.3253C43.8196 37.3253 40.9371 34.1136 40.9371 30.1693C40.9371 26.225 43.7636 23.0133 47.3178 23.0133C50.9 23.0133 53.7545 26.2532 53.6986 30.1693C53.6986 34.1136 50.9 37.3253 47.3178 37.3253Z" fill="white"/>
              </svg>
              <div className="text-white text-base font-medium">Discord</div>
              {lastUsed === 'discord' && (
                <div className="absolute right-3 text-xs text-gray-300">Last used</div>
              )}
            </button>
          </div>
          
          {/* Auto-login preference toggle */}
          <div className="flex items-center mb-4">
            <input
              type="checkbox"
              id="autoLogin"
              defaultChecked={getStorageItem('autoLogin') !== 'false'}
              onChange={(e) => {
                const newValue = e.target.checked ? 'true' : 'false';
                setStorageItem('autoLogin', newValue);
                // Update Firebase persistence when preference changes
                updatePersistence();
              }}
              className="w-4 h-4 text-blue-600 bg-gray-700 border-gray-600 rounded focus:ring-blue-500"
            />
            <label htmlFor="autoLogin" className="ml-2 text-sm text-gray-300">
              Sign in automatically next time
            </label>
          </div>
          
          {!storageAvailable && (
            <div className="mt-2 p-2 bg-yellow-800 bg-opacity-30 rounded text-yellow-300 text-xs">
              Warning: Local storage is not available. Your login preferences won't be saved between sessions.
            </div>
          )}
          
          {error && <p className="mt-4 text-red-500">{error}</p>}
        </div>
      </div>

      {/* Mobile Warning Popup */}
      <MobileWarningPopup 
        isVisible={showMobileWarning} 
        closePopup={() => setShowMobileWarning(false)} 
      />
    </div>
  );
}