// ImageRestorationPage.jsx

"use client";

import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { message } from 'antd';
import Cookies from 'js-cookie';
import CustomButton from '../components/shared/CustomButton';
import Gallery from '../components/shared/Gallery';
import SettingsPanel from '../components/shared/SettingsPanel';
import ImageDisplay from '../components/shared/ImageDisplay';
import DownloadSection from '../components/shared/DownloadSection';
import ZoomControls from '../components/shared/ZoomControls';
import SharedLayout from '../components/shared/SharedLayout';
import '../styles/UpscalerPage.css';
import usePreventRightClick from './usePreventRightClick';
import Hotjar from '@hotjar/browser';

const API_BASE = process.env.NODE_ENV === 'development'
  ? 'http://localhost:3001/api'
  : 'https://www.ek0go8g.vudoo.ai/api';

const ImageRestorationPage = () => {
  usePreventRightClick();
  const navigate = useNavigate();

  // Hotjar Integration
  useEffect(() => {
    Hotjar.init(process.env.REACT_APP_HOTJAR_ID, process.env.REACT_APP_HOTJAR_SNIPPET_VERSION);
  }, []);

  // State Variables
  const [uploadedImageUrl, setUploadedImageUrl] = useState('');
  const [restoredImageUrl, setRestoredImageUrl] = useState('');
  const [isRestoring, setIsRestoring] = useState(false);
  const [galleryImages, setGalleryImages] = useState([]);
  const [imageMetadata, setImageMetadata] = useState([]);
  const [selectedImage, setSelectedImage] = useState('');
  const [selectedMetadata, setSelectedMetadata] = useState({
    width: 0,
    height: 0,
    format: 'Unknown',
    scaleFactor: 2, // Fixed scale factor for restoration
    isProcessed: false,
  });
  const [zoomLevel, setZoomLevel] = useState(100);
  const [isGalleryCollapsed, setIsGalleryCollapsed] = useState(false);
  const [isDownloadSectionCollapsed, setIsDownloadSectionCollapsed] = useState(true);
  const [tokensAvailable, setTokensAvailable] = useState(null);
  const [selectedImageState, setSelectedImageState] = useState(null);
  const [nextPageToken, setNextPageToken] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const tokensRequired = 2; // Tokens needed for image restoration

  // Fetch user profile for token information
  const fetchUserProfile = useCallback(async () => {
    const token = Cookies.get('token');
    if (!token) {
      navigate('/login'); // Redirect to login if not authenticated
      return;
    }

    try {
      const response = await fetch(`${API_BASE}/profile`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) throw new Error('Failed to fetch user profile');

      const profileData = await response.json();
      setTokensAvailable(profileData.credits || 0);
    } catch (error) {
      console.error('Error fetching user profile:', error);
      message.error('Failed to fetch user profile.');
      navigate('/login'); // Redirect to login if fetching profile fails
    }
  }, [navigate]);

  // Deduct Credits Function
  const deductCredits = async (creditsCost) => {
    try {
      const token = Cookies.get('token');
      if (!token) {
        throw new Error('User not authenticated');
      }

      const response = await fetch(`${API_BASE}/credits`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ action: 'deduct', creditsCost }),
      });

      if (!response.ok) {
        throw new Error('Failed to deduct credits');
      }

      const result = await response.json();
      return result.newCredits; // Capture the new credits balance
    } catch (error) {
      message.error('Failed to deduct credits. Please try again.');
      throw error;
    }
  };

  // Initialize component and handle sessions
  useEffect(() => {
    fetchUserProfile();

    const activeSession = sessionStorage.getItem('activeRestorationSession');
    if (activeSession) {
      // Load the specific session
      const session = JSON.parse(activeSession);

      // Ensure session has originalImage and variants
      if (!session.originalImage) {
        console.error('Session missing originalImage');
        message.error('Invalid session data.');
        sessionStorage.removeItem('activeRestorationSession');
        return;
      }

      setUploadedImageUrl(session.originalImage.url);
      const originalImageUrl = session.originalImage.url;

      // Prepare gallery images and metadata
      const initialGalleryImages = [originalImageUrl];
      const initialMetadata = [{
        width: session.originalImage.width || 0,
        height: session.originalImage.height || 0,
        format: session.originalImage.format || 'Unknown',
        scaleFactor: session.originalImage.scaleFactor || 1, // Original image has a scale factor of 1
        isProcessed: false, // Original image is not processed
      }];

      // Handle variants if they exist, avoid duplicates
      if (Array.isArray(session.variants)) {
        session.variants.forEach((variant) => {
          if (variant.url && !initialGalleryImages.includes(variant.url)) {
            initialGalleryImages.push(variant.url);
            initialMetadata.push({
              width: variant.width || 0,
              height: variant.height || 0,
              format: variant.format || 'Unknown',
              scaleFactor: variant.scaleFactor || 2,
              isProcessed: true,
            });
          }
        });
      }

      setGalleryImages(initialGalleryImages);
      setImageMetadata(initialMetadata);

      // Set the initially selected image to the first variant, if it exists, otherwise the original image
      if (initialGalleryImages.length > 1) {
        setSelectedImage(initialGalleryImages[1]);
        setSelectedImageState(initialGalleryImages[1]);
        setSelectedMetadata(initialMetadata[1]);
      } else {
        setSelectedImage(originalImageUrl);
        setSelectedImageState(originalImageUrl);
        setSelectedMetadata(initialMetadata[0]);
      }

      // Remove 'activeRestorationSession' from sessionStorage after loading
      sessionStorage.removeItem('activeRestorationSession');
    } else {
      // No active session, proceed with normal flow
      const imageUrl = sessionStorage.getItem('uploadedImageUrl');
      if (imageUrl) {
        setUploadedImageUrl(imageUrl);
        setGalleryImages([imageUrl]);
        setSelectedImage(imageUrl);
        setSelectedImageState(imageUrl);

        const img = new Image();
        img.src = imageUrl;
        img.onload = () => {
          const metadata = {
            width: img.width,
            height: img.height,
            format: imageUrl.split('.').pop().toUpperCase(),
            scaleFactor: 1, // Original image should have scale factor of 1
            isProcessed: false,
          };
          setImageMetadata([metadata]);
          setSelectedMetadata(metadata);
        };
      }
    }
  }, [fetchUserProfile]);

  // Handle the restoration process
  const handleRestoreImage = async () => {
    const token = Cookies.get('token');
    if (!token) {
      message.error('Please log in first.');
      navigate('/login'); // Redirect to login if not authenticated
      return;
    }

    if (tokensAvailable < tokensRequired) {
      message.error('Not enough tokens. Please purchase more.');
      return;
    }

    if (!uploadedImageUrl) {
      message.error('Please upload an image first.');
      return;
    }

    setIsRestoring(true);

    try {
      // Fetch the image file from the uploaded URL
      const response = await fetch(uploadedImageUrl);
      if (!response.ok) {
        throw new Error('Failed to fetch the uploaded image');
      }
      const blob = await response.blob();

      const formData = new FormData();
      formData.append('file', blob, 'restored_image.jpg');
      formData.append('tokensSpent', tokensRequired); // Send tokens information in the payload

      // Make a request to your backend to initiate image restoration
      const result = await fetch(`${API_BASE}/imageRestoration`, {
        method: 'POST',
        body: formData,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!result.ok) {
        throw new Error(`Failed to restore the image. Status: ${result.status}`);
      }

      const data = await result.json();

      // Updated handling of restored image URL
      if (data.restoredImage) {
        const restoredUrl = data.restoredImage;

        // Fetch metadata for the restored image and update the state accordingly
        const img = new Image();
        img.src = restoredUrl;
        img.onload = async () => {
          const metadata = {
            width: img.width,
            height: img.height,
            format: restoredUrl.split('.').pop().toUpperCase(),
            scaleFactor: 2, // Fixed scale factor for restoration
            isProcessed: true,
          };

          setGalleryImages((prevGallery) => [...prevGallery, restoredUrl]);
          setImageMetadata((prevMetadata) => [...prevMetadata, metadata]);

          // Deduct credits from available tokens after successful restoration
          await deductCredits(tokensRequired);

          // Fetch the updated user profile to get the latest token count
          await fetchUserProfile();

          message.success('Image restored successfully!');

          // Update the selected image to the restored one
          setSelectedMetadata(metadata);
          setSelectedImageState(restoredUrl); // Automatically select the restored image

          // Automatically expand the DownloadSection after restoration
          setIsDownloadSectionCollapsed(false);

          // Optionally, save the session to sessionStorage
          const session = {
            originalImage: {
              url: uploadedImageUrl,
              width: selectedMetadata.width,
              height: selectedMetadata.height,
              format: selectedMetadata.format,
              scaleFactor: selectedMetadata.scaleFactor,
            },
            variants: [
              ...(sessionStorage.getItem('activeRestorationSession')
                ? JSON.parse(sessionStorage.getItem('activeRestorationSession')).variants
                : []),
              {
                url: restoredUrl,
                width: metadata.width,
                height: metadata.height,
                format: metadata.format,
                scaleFactor: metadata.scaleFactor,
              },
            ],
          };
          sessionStorage.setItem('activeRestorationSession', JSON.stringify(session));
        };
      } else {
        throw new Error('Restored image URL not found in the response.');
      }
    } catch (error) {
      console.error('Error restoring image:', error);
      message.error('Restoration failed. Please try again.');
    } finally {
      setIsRestoring(false);
    }
  };

  // Handle image download
  const handleDownload = (format) => {
    if (!selectedImageState) {
      console.error('No image selected for download.');
      message.error('No image selected for download. Please select a variant.');
      return;
    }

    console.log('Starting restoration image download process for:', selectedImageState);
    console.log('Restoration metadata:', selectedMetadata);

    // Create a canvas to resize the image to the selected resolution
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const img = new Image();
    img.crossOrigin = 'anonymous'; // Handle CORS issues if needed
    
    // Only use the proxy if the URL is from Hetzner Object Storage
    const originalSrc = selectedImageState;
    const imageUrl = originalSrc.includes('fsn1.your-objectstorage.com') 
      ? `/api/image-proxy?url=${encodeURIComponent(originalSrc)}`
      : originalSrc;
    
    console.log('Using proxied image URL for restoration download:', imageUrl);
    
    // Set up a timeout for stalled loading
    const timeoutId = setTimeout(() => {
      console.error('Restoration image load timed out after 30 seconds');
      message.error('Download failed - image load timed out. Please try again.');
    }, 30000);

    img.onload = () => {
      clearTimeout(timeoutId);
      console.log(`Restoration image loaded successfully - dimensions: ${img.width}x${img.height}`);
      
      // Set the canvas size to match the selected metadata resolution or use image dimensions
      const width = selectedMetadata?.width || img.width;
      const height = selectedMetadata?.height || img.height;
      
      console.log(`Using restoration dimensions: ${width}x${height}`);
      canvas.width = width;
      canvas.height = height;

      try {
        // Draw the image onto the canvas with the specified resolution
        ctx.drawImage(img, 0, 0, width, height);
        console.log('Restoration image drawn to canvas successfully');

        // Create a blob from the canvas content
        canvas.toBlob((blob) => {
          if (blob) {
            const blobSizeKB = (blob.size / 1024).toFixed(2);
            console.log(`Restoration blob created successfully - size: ${blobSizeKB}KB`);
            
            // Safety check for small blobs that might indicate corruption
            if (blob.size < 1000) {
              console.error(`Restoration blob size too small: ${blob.size} bytes`);
              message.error('Download failed - image processing error. Please try again.');
              return;
            }
            
            // Create a download link for the user
            const url = URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = `vudoo_restored_${width}x${height}.${format.toLowerCase()}`;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            URL.revokeObjectURL(url);
            message.success(`Downloading restored ${format.toUpperCase()} file...`);
            console.log(
              `Restoration download successful: vudoo_restored_${width}x${height}.${format.toLowerCase()} - Size: ${blobSizeKB}KB`
            );
          } else {
            message.error('Failed to create a restoration image for download.');
            console.error('Canvas toBlob returned null for restoration image.');
          }
        }, 
        `image/${format.toLowerCase()}`,
        1.0); // Use maximum quality
      } catch (canvasError) {
        console.error('Canvas drawing error for restoration:', canvasError);
        message.error('Failed to process restoration image for download. Please try again.');
      }
    };

    img.onerror = (e) => {
      clearTimeout(timeoutId);
      console.error('Failed to load restoration image for download:', e);
      message.error('Download failed. Please try again.');
    };
    
    img.src = imageUrl;
  };

  const headerProps = {
    newAction: 'New Restoration',
    newActionPath: '/upload?type=restoration',
  };

  const sidebarContent = (
    <>
      <Gallery
        images={galleryImages}
        imageMetadata={imageMetadata}
        onThumbnailClick={(imageUrl, metadata) => {
          setSelectedImage(imageUrl); // Update selected image
          setSelectedMetadata(metadata);
          setSelectedImageState(imageUrl); // Update selectedImageState
        }}
        isCollapsed={isGalleryCollapsed}
        toggleCollapse={() => setIsGalleryCollapsed(!isGalleryCollapsed)}
        isRestorationPage={true} // Optional: Flag if Gallery needs to handle differently
      />

      <SettingsPanel
        settings={{
          scaleFactor: selectedMetadata.scaleFactor,
          outputResolution:
            selectedMetadata.width > 0 && selectedMetadata.height > 0
              ? `${selectedMetadata.width} x ${selectedMetadata.height}`
              : 'Loading',
          tokensAvailable,
          tokensRequired,
          options: [
            // Add restoration-specific options here if needed
          ],
          actionButton: (
            <CustomButton onClick={handleRestoreImage} disabled={isRestoring}>
              {isRestoring ? 'Restoring...' : 'Restore Image'}
            </CustomButton>
          ),
        }}
        onToggle={() => {}}
        onChange={() => {}}
        isRestoration={true} // Enables restoration mode
      />

      {/* Download Section - Always Rendered, Controlled via isDownloadSectionCollapsed */}
      <DownloadSection
        handleDownload={handleDownload}
        outputResolution={
          selectedMetadata.width > 0 && selectedMetadata.height > 0
            ? `${selectedMetadata.width} x ${selectedMetadata.height}`
            : 'Loading'
        }
        isCollapsed={isDownloadSectionCollapsed}
        toggleCollapse={() =>
          setIsDownloadSectionCollapsed(!isDownloadSectionCollapsed)
        }
      />
    </>
  );

  const zoomControlsProps = {
    zoomLevel,
    onZoomIn: () => setZoomLevel((prev) => Math.min(prev + 10, 300)),
    onZoomOut: () => setZoomLevel((prev) => Math.max(prev - 10, 50)),
    onResetZoom: () => setZoomLevel(100),
  };

  return (
    <SharedLayout
      headerProps={headerProps}
      sidebarContent={sidebarContent}
      zoomControlsProps={zoomControlsProps}
    >
      <ImageDisplay
        uploadedImageUrl={uploadedImageUrl}
        selectedImageUrl={selectedImageState}
        isProcessing={isRestoring}
        isProcessed={selectedMetadata.isProcessed}
        zoomLevel={zoomLevel}
        onResetZoom={zoomControlsProps.onResetZoom}
        isDraggingEnabled={true}
        upscaledImageUrl={restoredImageUrl}
        processType="restoring"
      />
    </SharedLayout>
  );
};

export default ImageRestorationPage;
