/**
 * Converts a direct object storage URL to a proxied URL to avoid CORS issues
 * @param {string} originalUrl - The original object storage URL
 * @returns {string} - The proxied URL
 */
import { getCachedImageUrl, CACHE_DURATIONS } from './cacheUtils';

export const getProxiedImageUrl = (originalUrl) => {
  if (!originalUrl) return '';
  
  // If it's already a relative URL or from our own domain, return as is
  if (originalUrl.startsWith('/') || originalUrl.includes('app.vudoo.ai')) {
    return originalUrl;
  }
  
  try {
    // Check if this is an object storage URL that needs proxying
    if (originalUrl.includes('fsn1.your-objectstorage.com')) {
      // First, apply caching to the URL
      const cachedUrl = getCachedImageUrl(originalUrl, CACHE_DURATIONS.LONG);
      
      // Then proxy the cached URL
      return `/api/image-proxy?url=${encodeURIComponent(cachedUrl)}`;
    }
    
    // If it doesn't match our object storage, return as is
    return originalUrl;
  } catch (error) {
    console.error('Error creating proxied URL:', error);
    return originalUrl;
  }
};

/**
 * Determines if an image should be proxied based on its URL
 * @param {string} url - The image URL to check
 * @returns {boolean} - Whether the image should be proxied
 */
export const shouldProxyImage = (url) => {
  if (!url) return false;
  
  // If the URL is for Hetzner object storage, it should be proxied
  return url.includes('fsn1.your-objectstorage.com');
};

/**
 * Helper to get an image URL that will work around CORS issues
 * @param {string} url - The original image URL
 * @returns {string} - A CORS-friendly image URL
 */
export const getCORSFriendlyImageUrl = (url) => {
  if (shouldProxyImage(url)) {
    return getProxiedImageUrl(url);
  }
  return url;
};

/**
 * Creates a blob URL from an image URL, handling CORS issues
 * @param {string} url - The original image URL
 * @returns {Promise<string>} - A Promise that resolves to a blob URL
 */
export const createBlobUrl = async (url) => {
  // First, determine if we need to proxy the image
  const proxyUrl = shouldProxyImage(url) ? getProxiedImageUrl(url) : getCachedImageUrl(url);
  
  try {
    // Fetch the image using the appropriate URL
    const response = await fetch(proxyUrl, {
      mode: 'cors',
      cache: 'force-cache',
      headers: {
        'Cache-Control': 'max-age=86400' // 1 day cache 
      }
    });
    
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    
    // Get the blob from the response
    const blob = await response.blob();
    
    // Create and return a blob URL
    return URL.createObjectURL(blob);
  } catch (error) {
    console.error('Error creating blob URL:', error);
    // If proxy fails, try direct URL as fallback
    if (proxyUrl !== url) {
      console.warn('Proxy failed, trying direct URL as fallback');
      try {
        const directResponse = await fetch(url, { mode: 'no-cors' });
        const blob = await directResponse.blob();
        return URL.createObjectURL(blob);
      } catch (fallbackError) {
        console.error('Fallback also failed:', fallbackError);
        throw error; // Throw the original error
      }
    }
    throw error;
  }
};

/**
 * Ensures that a URL uses HTTPS instead of HTTP
 * @param {string} url - The URL to enforce HTTPS on
 * @returns {string} - The URL with HTTPS protocol
 */
export const enforceHttps = (url) => {
  if (!url) return '';
  
  // If it's a relative URL, return as is
  if (url.startsWith('/')) {
    return url;
  }
  
  // Replace http:// with https://
  if (url.startsWith('http://')) {
    return url.replace('http://', 'https://');
  }
  
  return url;
};

/**
 * Complete URL processor that handles both HTTPS enforcement, caching and proxying
 * @param {string} url - The original URL
 * @returns {string} - Processed URL with HTTPS and potential proxying
 */
export const processImageUrl = (url) => {
  const httpsUrl = enforceHttps(url);
  const cachedUrl = shouldProxyImage(httpsUrl) ? 
    getCORSFriendlyImageUrl(httpsUrl) : 
    getCachedImageUrl(httpsUrl);
  return cachedUrl;
}; 