// src/firebase.js
import { initializeApp, getApps, getApp } from 'firebase/app';
import { getAuth, setPersistence, browserLocalPersistence, browserSessionPersistence, signInWithCustomToken } from 'firebase/auth';

// Helper functions for localStorage with fallbacks
const isLocalStorageAvailable = () => {
  try {
    const testKey = '__test__';
    localStorage.setItem(testKey, testKey);
    localStorage.removeItem(testKey);
    console.log('firebase.js: localStorage is available');
    return true;
  } catch (e) {
    console.error('firebase.js: localStorage is not available:', e);
    return false;
  }
};

// Memory fallback if localStorage isn't available
const memoryStorage = {};

const getStorageItem = (key, defaultValue = null) => {
  try {
    if (isLocalStorageAvailable()) {
      const value = localStorage.getItem(key);
      console.log(`firebase.js: Retrieved from localStorage: ${key} = ${value}`);
      return value;
    } else {
      console.log(`firebase.js: Retrieved from memoryStorage: ${key} = ${memoryStorage[key]}`);
      return memoryStorage[key] || defaultValue;
    }
  } catch (e) {
    console.error(`firebase.js: Error getting ${key} from storage:`, e);
    return defaultValue;
  }
};

const setStorageItem = (key, value) => {
  try {
    if (isLocalStorageAvailable()) {
      localStorage.setItem(key, value);
      console.log(`firebase.js: Saved to localStorage: ${key} = ${value}`);
    } else {
      memoryStorage[key] = value;
      console.log(`firebase.js: Saved to memoryStorage: ${key} = ${value}`);
    }
    return true;
  } catch (e) {
    console.error(`firebase.js: Error setting ${key} in storage:`, e);
    return false;
  }
};

let auth;

const initializeFirebase = async () => {
  try {
    // Fetch Firebase configuration from your backend
    const response = await fetch('https://www.ek0go8g.vudoo.ai/api/firebaseConfig');
    if (!response.ok) {
      throw new Error('Failed to fetch Firebase configuration');
    }
    const encodedConfig = await response.json();
    
    // Decode configuration
    const decode = (str) => atob(str);
    const decodeConfig = (encodedConfig) => ({
      apiKey: decode(encodedConfig.apiKey),
      authDomain: decode(encodedConfig.authDomain),
      projectId: decode(encodedConfig.projectId),
      storageBucket: decode(encodedConfig.storageBucket),
      messagingSenderId: decode(encodedConfig.messagingSenderId),
      appId: decode(encodedConfig.appId),
    });
    
    const firebaseConfig = decodeConfig(encodedConfig);
    
    // Initialize Firebase
    const app = initializeApp(firebaseConfig);
    auth = getAuth(app);
    
    // Check auto-login preference to determine persistence type
    const shouldAutoLogin = getStorageItem('autoLogin') !== 'false'; // Default to true
    
    // Set persistence based on auto-login preference
    try {
      // Use local persistence (survives browser restarts) only if auto-login is enabled
      // Otherwise use session persistence (cleared when browser is closed)
      const persistenceType = shouldAutoLogin ? browserLocalPersistence : browserSessionPersistence;
      await setPersistence(auth, persistenceType);
      console.log(`Firebase persistence set to ${shouldAutoLogin ? 'LOCAL' : 'SESSION'}`);
    } catch (persistenceError) {
      console.error('Error setting persistence:', persistenceError);
    }
    
    console.log('Firebase initialized successfully');
  } catch (error) {
    console.error('Error initializing Firebase:', error);
  }
};

// Function to update persistence when auto-login preference changes
export const updatePersistence = async () => {
  if (!auth) return;
  
  const shouldAutoLogin = getStorageItem('autoLogin') !== 'false';
  try {
    const persistenceType = shouldAutoLogin ? browserLocalPersistence : browserSessionPersistence;
    await setPersistence(auth, persistenceType);
    console.log(`Firebase persistence updated to ${shouldAutoLogin ? 'LOCAL' : 'SESSION'}`);
  } catch (error) {
    console.error('Error updating persistence:', error);
  }
};

// Set default values
if (getStorageItem('autoLogin') === null) {
  setStorageItem('autoLogin', 'true');
}

// Ensure Firebase is initialized before using
initializeFirebase();

// Function to sign in with a custom token (for Discord auth)
export const signInWithToken = async (customToken) => {
  try {
    const userCredential = await signInWithCustomToken(auth, customToken);
    return userCredential.user;
  } catch (error) {
    console.error('Error signing in with custom token:', error);
    throw error;
  }
};

export { auth, initializeFirebase };
