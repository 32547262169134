// src/pages/UploadPage.js

import React, { useState, useEffect, useCallback, useRef } from "react";
import { Spin, message, Modal } from "antd";
import { useNavigate, useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import "../styles/UploadPage.css";
import Lottie from "react-lottie";
import lottieAnimation from "../components/Assets/loading-animation.json";
import Hotjar from "@hotjar/browser";
import MobileUploadPage from "./MobileUploadPage";
import ImageModal from "../components/ImageModal";
import LibraryLearnSection from "../components/LibraryLearnSection";
import actionCards from "../components/ActionCardsData";
import { useActionCard } from "../components/ActionCardContext";
import debounce from "lodash.debounce";
import ZoomControls from "../components/shared/ZoomControls";
import UploadSection from "../components/UploadSection"; // Import your UploadSection
import { API_CONFIG, FEATURES } from "../utils/config"; // Import config

const UploadPage = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [isUploading, setIsUploading] = useState(false);
  const [pageTitle, setPageTitle] = useState("");
  const [instructionText, setInstructionText] = useState("");
  const [loadingGallery, setLoadingGallery] = useState(true);
  const [tokensAvailable, setTokensAvailable] = useState(null);
  const [zoomLevel, setZoomLevel] = useState(100);
  const [hoverBackground, setHoverBackground] = useState("");
  const [loomVideoUrl, setLoomVideoUrl] = useState("");
  const [initialVideoUrl, setInitialVideoUrl] = useState("");
  const [videoThumbnail, setVideoThumbnail] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  const { selectedCard, setSelectedCard } = useActionCard();

  const [sessions, setSessions] = useState([]);
  const [selectedSession, setSelectedSession] = useState(null);
  const [isSessionModalOpen, setIsSessionModalOpen] = useState(false);

  // Refs for library and upload container
  const librarySectionRef = useRef(null);
  const uploadContainerRef = useRef(null);

  // New state for dynamic spacing
  const [headerHeight, setHeaderHeight] = useState(64); // Default header height

  // NEW: controlling whether the library is expanded (just like in LandingPage)
  const [isLibraryExpanded, setIsLibraryExpanded] = useState(false);

  const lottieOptions = {
    loop: true,
    autoplay: true,
    animationData: lottieAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  // Smooth spacing/height adjustments
  const adjustSpacing = useCallback(() => {
    if (librarySectionRef.current && uploadContainerRef.current) {
      const screenHeight = Math.max(window.innerHeight, 800); // Minimum height like landing page
      const minMargin = 20;
      const maxMargin = 150;

      // Calculate margin using the same formula as landing page
      const calculatedMargin =
        minMargin +
        ((screenHeight - 800) / 800) * (maxMargin - minMargin);

      // Clamp the margin between min and max values
      const clampedMargin = Math.max(
        minMargin,
        Math.min(maxMargin, calculatedMargin)
      );

      // Apply the spacing
      librarySectionRef.current.style.marginTop = `${clampedMargin}px`;
    }
  }, []);

  useEffect(() => {
    const handleResize = debounce(() => {
      setIsMobile(window.innerWidth <= 768);
      adjustSpacing();
    }, 100);

    // Initial adjustment
    adjustSpacing();

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [adjustSpacing]);

  // Initialize Hotjar
  useEffect(() => {
    Hotjar.init(
      process.env.REACT_APP_HOTJAR_ID,
      process.env.REACT_APP_HOTJAR_SNIPPET_VERSION
    );
  }, []);

  // Refresh user profile every 30 minutes
  useEffect(() => {
    const refreshUserProfile = async () => {
      try {
        await fetchUserProfile();
      } catch (error) {
        console.error("Failed to refresh user profile:", error);
        navigate("/login");
      }
    };
    const intervalId = setInterval(refreshUserProfile, 30 * 60 * 1000);
    return () => clearInterval(intervalId);
  }, [navigate]);

  // Fetch data based on query params
  useEffect(() => {
    const fetchData = async () => {
      const queryParams = new URLSearchParams(location.search);
      const type = queryParams.get("type");

      // Find matching card data
      const cardData = actionCards.find((card) => card.type === type);
      if (cardData) {
        setPageTitle(cardData.title);
        setInstructionText(cardData.description);
        setInitialVideoUrl(cardData.loomVideoUrl);
        setHoverBackground(cardData.uploadHoverImage);
        setVideoThumbnail(cardData.thumbnailImage);
        setSelectedCard(cardData);
      } else {
        message.error("Invalid action type selected.");
        navigate("/home");
        return;
      }

      try {
        setLoadingGallery(true);
        await fetchUserProfile();
        await fetchGalleryImages(type);
      } catch (error) {
        console.error("Failed to fetch data:", error);
        navigate("/login");
      } finally {
        setLoadingGallery(false);
      }
    };

    fetchData();
  }, [location, navigate, setSelectedCard]);

  // Add scroll handler to match landing page behavior
  useEffect(() => {
    const handlePageScroll = () => {
      if (librarySectionRef.current) {
        const pageHeight = document.documentElement.scrollHeight - window.innerHeight;
        const libraryHeight =
          librarySectionRef.current.scrollHeight -
          librarySectionRef.current.clientHeight;

        // Map scroll position
        const scrollRatio = window.scrollY / pageHeight;
        librarySectionRef.current.scrollTop = scrollRatio * libraryHeight;
      }
    };

    window.addEventListener("scroll", handlePageScroll);
    return () => window.removeEventListener("scroll", handlePageScroll);
  }, []);

  // Fetch user profile with hardcoded production endpoint
  const fetchUserProfile = async () => {
    const token = Cookies.get("token");
    if (!token) {
      navigate("/login");
      return;
    }

    try {
      const response = await fetch("https://www.ek0go8g.vudoo.ai/api/profile", {
        method: "GET",
        headers: { Authorization: `Bearer ${token}` },
      });

      if (!response.ok) {
        throw new Error("Failed to fetch user profile");
      }

      const profileData = await response.json();
      setTokensAvailable(profileData.credits || 0);
    } catch (error) {
      console.error("Error fetching user profile:", error);
      message.error("Failed to load user profile. Redirecting to login.");
      navigate("/login");
    }
  };

  // Fetch gallery images with hardcoded production endpoints
  const fetchGalleryImages = async (type) => {
    const token = Cookies.get("token");
    if (!token) {
      navigate("/login");
      return;
    }

    const endpoint =
      type === "restoration"
        ? "https://www.ek0go8g.vudoo.ai/api/imageRestoreGallery"
        : "https://www.ek0go8g.vudoo.ai/api/imageUpscaleGallery";

    try {
      const response = await fetch(endpoint, {
        method: "GET",
        headers: { Authorization: `Bearer ${token}` },
      });

      if (!response.ok) {
        throw new Error("Failed to fetch images");
      }

      const jsonData = await response.json();
      const { sessions } = jsonData;

      const normalizedImages = (sessions || []).map((session) => ({
        ...session,
        uploadDate:
          session.dateProcessed || session.createdAt || "Unknown Date",
        toolUsed:
          type === "restoration" ? "Image Restoration" : "Image Upscaling",
      }));

      // De-duplicate or modify sessions as needed
      const imagesMap = new Map();
      normalizedImages.forEach((image) => {
        imagesMap.set(image.sessionId, image);
      });

      const uniqueImages = Array.from(imagesMap.values());
      setSessions(uniqueImages);
    } catch (error) {
      console.error("Error fetching images:", error);
      message.error("Failed to load gallery images. Redirecting to login.");
      navigate("/login");
    }
  };

  // Handle file upload with hardcoded production endpoint
  const handleFileUpload = async (info) => {
    const file = info.file.originFileObj || info.file;
    if (!file) return;

    setIsUploading(true);
    const formData = new FormData();
    formData.append("file", file);
    
    // Add hybrid storage flag if enabled
    if (FEATURES.useHybridStorage) {
      formData.append("useHybridStorage", "true");
    }

    try {
      const token = Cookies.get("token");
      if (!token) {
        message.error("You must be logged in to upload images.");
        navigate("/login");
        setIsUploading(false);
        return;
      }

      const response = await fetch("https://www.ek0go8g.vudoo.ai/api/upload", {
        method: "POST",
        headers: { Authorization: `Bearer ${token}` },
        body: formData,
      });

      if (!response.ok) {
        throw new Error("Failed to upload image");
      }

      const data = await response.json();
      
      // Use the best URL available
      const imageUrl = data.cloudinaryUrl || data.url || data.s3Url;
      
      if (!imageUrl) {
        console.error('No valid image URL in the response:', data);
        throw new Error('No valid image URL in the response');
      }

      // Store the URL in session storage for other pages to use
      sessionStorage.setItem("uploadedImageUrl", imageUrl);
      
      // Also store both S3 and Cloudinary URLs if available for advanced usage
      if (data.s3Url) {
        sessionStorage.setItem("s3ImageUrl", data.s3Url);
      }
      
      if (data.cloudinaryUrl && data.cloudinaryUrl !== data.s3Url) {
        sessionStorage.setItem("cloudinaryImageUrl", data.cloudinaryUrl);
      }
      
      // Store info about whether CDN is being used
      sessionStorage.setItem("usingCdn", data.usingCdn ? "true" : "false");
      
      message.success("Image uploaded successfully!");

      if (selectedCard?.type === "restoration") {
        navigate("/restoration");
      } else if (
        selectedCard?.type === "upscaler" ||
        selectedCard?.type === "upscaler-portrait" ||
        selectedCard?.type === "upscaler-highres" ||
        selectedCard?.type === "upscaler-product" ||
        selectedCard?.type === "upscaler-exterior"

      ) {
        navigate("/upscaler");
      } else {
        navigate("/home");
      }
    } catch (error) {
      console.error("Error uploading image:", error);
      message.error("Upload failed!");
    } finally {
      setIsUploading(false);
    }
  };

  const handleZoomIn = () => {
    setZoomLevel((prevZoom) => Math.min(prevZoom + 10, 200));
  };

  const handleZoomOut = () => {
    setZoomLevel((prevZoom) => Math.max(prevZoom - 10, 50));
  };

  const handleModalOpen = () => {
    setIsModalVisible(true);
    const videoUrlWithParams = `${initialVideoUrl}&hide_owner=true&hideEmbedTopBar=true`;
    setLoomVideoUrl(videoUrlWithParams);
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
    setLoomVideoUrl("");
  };

  const handleSessionClick = (session) => {
    setSelectedSession(session);
    setIsSessionModalOpen(true);
  };

  const handleCloseSessionModal = () => {
    setIsSessionModalOpen(false);
    setSelectedSession(null);
  };

  const handleContinueWithProject = (session) => {
    // Implement or customize your logic to continue with the project
    console.log("Continuing with project:", session);
    // You can navigate or perform other actions here
  };

  if (isMobile) {
    return <MobileUploadPage />;
  }

  return (
    <div className="w-full min-h-screen bg-[#151515] flex flex-col items-center relative">
      {/* Header */}
<header
        className={`fixed top-0 left-0 right-0 flex justify-between items-center h-16 px-4 z-50 bg-[#151515]`}
        style={{ height: `${headerHeight}px` }}
      >
        <div
          className="h-10 w-10 cursor-pointer flex items-center justify-center bg-[#212121] border border-white/20 rounded-lg hover:bg-[#3a3a3a] transition-all duration-500 ease-in-out"
          onClick={() => navigate("/home")}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            fill="none"
            viewBox="0 0 20 20"
          >
            <path
              stroke="var(--white)"
              strokeWidth="1.5"
              d="M2.415 8.711a2.25 2.25 0 0 1 1.024-1.886l5.333-3.467a2.25 2.25 0 0 1 2.453 0l5.333 3.467a2.25 2.25 0 0 1 1.024 1.886v6.79c0 .69-.56 1.25-1.25 1.25h-3.667a.25.25 0 0 1-.25-.25v-3a1.75 1.75 0 0 0-1.75-1.75H9.332a1.75 1.75 0 0 0-1.75 1.75v3a.25.25 0 0 1-.25.25H3.665c-.69 0-1.25-.56-1.25-1.25V8.71Z"
            />
          </svg>
        </div>
   
      </header>

      {/* Upload Section */}
      <div
        ref={uploadContainerRef}
        className={`w-full transition-all duration-500 ${
          isLibraryExpanded
            ? "opacity-0 pointer-events-none mb-0"
            : "opacity-100 mb-16"
        }`}
        style={{ marginTop: `${headerHeight + 20}px` }} // Adjust based on header height
      >
        <UploadSection
          pageTitle={pageTitle}
          instructionText={instructionText}
          videoThumbnail={videoThumbnail}
          hoverBackground={hoverBackground}
          handleModalOpen={handleModalOpen}
          handleFileUpload={handleFileUpload}
          isUploading={isUploading}
          isModalVisible={isModalVisible}
          lottieAnimation={lottieAnimation} // Pass Lottie data if needed
        />
      </div>

      {/* Library Learn Section with Dynamic Spacing */}
      <div
        ref={librarySectionRef}
        className={`library-section spacing-container transition-all duration-300 ${
          isLibraryExpanded
            ? "fixed top-16 left-0 right-0 bottom-0 z-40 bg-[#151515] overflow-auto"
            : "relative"
        }`}
        style={{
          marginTop: isLibraryExpanded ? `${headerHeight}px` : undefined,
        }}
      >
        <LibraryLearnSection
          sessions={sessions}
          onSessionClick={handleSessionClick}
          onContinueWithProject={handleContinueWithProject}
          onExpandChange={setIsLibraryExpanded}
          scrollThreshold={10} // Custom threshold value
        />
      </div>

      {/* Zoom Controls */}
      <div
        className={`transition-all duration-500 ${
          isLibraryExpanded ? "opacity-0 pointer-events-none mb-0" : "opacity-100 mb-16"
        }`}
      >
        <ZoomControls
          zoomLevel={zoomLevel}
          onZoomIn={handleZoomIn}
          onZoomOut={handleZoomOut}
        />
      </div>



      {/* Session Image Modal */}
      <ImageModal
        isOpen={isSessionModalOpen}
        onClose={handleCloseSessionModal}
        session={selectedSession}
        onContinue={handleContinueWithProject}
      />

      {/* Loom/Video Modal */}
      <Modal
        visible={isModalVisible && !isLibraryExpanded}
        onCancel={handleModalClose}
        footer={null}
        centered
        width="80%"
        maskClosable={true}
        keyboard={true}
        destroyOnClose={true}
        bodyStyle={{
          padding: 0,
          margin: 0,
          height: "auto",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#151515",
        }}
        closeIcon={
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="white"
            viewBox="0 0 24 24"
          >
            <path d="M18.364 5.636a1 1 0 0 0-1.414 0L12 10.586 7.05 5.636a1 1 0 0 0-1.415 1.415L10.586 12l-4.95 4.95a1 1 0 1 0 1.415 1.415L12 13.414l4.95 4.95a1 1 0 0 0 1.414-1.415L13.414 12l4.95-4.95a1 1 0 0 0 0-1.414Z" />
          </svg>
        }
      >
        <div
          style={{
            width: "100%",
            position: "relative",
            paddingBottom: "56.25%",
            height: 0,
            backgroundColor: "#151515",
          }}
        >
          <iframe
            src={loomVideoUrl}
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              border: 0,
              visibility: "hidden",
            }}
            onLoad={(e) => {
              e.target.style.visibility = "visible";
            }}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Loom Video"
          />
        </div>
      </Modal>
    </div>
  );
};

export default UploadPage;