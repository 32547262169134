// src/components/ProfileCircle.js

import React, { useState, useEffect, useContext } from "react";
import { Dropdown, Avatar, Spin, message } from "antd";
import { UserOutlined } from "@ant-design/icons";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { ProfileContext } from "../ProfileContext"; // Adjust the path as necessary

const ProfileCircle = () => {
  // Consume the avatar and loading state from ProfileContext
  const {
    avatar: contextAvatar,
    loading: contextLoading,
    handleLogout,
  } = useContext(ProfileContext);

  const [profileData, setProfileData] = useState({
    avatar: "/placeholder.svg?height=64&width=64",
    email: "",
    plan: "Free Plan",
    tokens: 0,
  });
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const fetchUserProfile = async () => {
    const token = Cookies.get("token");
    if (!token) {
      message.error("User not authenticated");
      setLoading(false);
      return;
    }

    try {
      const response = await fetch("https://www.ek0go8g.vudoo.ai/api/profile", {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) throw new Error("Failed to fetch user profile");
      const data = await response.json();
      setProfileData({
        avatar: data.photoURL || "/placeholder.svg?height=64&width=64",
        email: data.email || "",
        plan: data.plan || "Free Plan",
        tokens: data.credits || 0,
      });
    } catch (error) {
      console.error("Error fetching user profile:", error);
      message.error("Failed to load user profile.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUserProfile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Synchronize the avatar from ProfileContext with local profileData state
  useEffect(() => {
    if (contextAvatar) {
      setProfileData((prevData) => ({
        ...prevData,
        avatar: contextAvatar,
      }));
    }
  }, [contextAvatar]);

  // Updated handleLogout function with more aggressive logout procedure
  const handleLogoutAndRedirect = async () => {
    try {
      // Import Firebase auth functions
      const { signOut } = await import("firebase/auth");
      const { auth } = await import("../firebase");
      
      // Clear auth token cookie first
      Cookies.remove("token", { path: '/' });
      
      // Clear all login-related localStorage items
      localStorage.removeItem('lastUsed');
      localStorage.removeItem('autoLogin');
      localStorage.removeItem('savedAutoLoginPreference');
      localStorage.removeItem('recentlyLoggedOut');
      
      // Set a flag to completely block auto-login until manual login
      localStorage.setItem('forceManualLogin', 'true');
      
      // Call the context logout function to clear user data
      handleLogout();
      
      // Sign out from Firebase last (after clearing cookies and local storage)
      await signOut(auth);
      
      // Add a delay to ensure everything is processed before redirect
      setTimeout(() => {
        // Redirect to the login page
        navigate("/login");
      }, 100);
    } catch (error) {
      console.error("Logout error:", error);
      message.error("Failed to log out properly. Please try again.");
    }
  };

  const profileMenu = (
    <div className="mb-3 w-60 h-auto px-2 pt-1.5 pb-3 bg-[var(--coal-800)] rounded-lg border border-white/15 flex flex-col justify-start items-start gap-1">
      <div className="self-stretch p-2 rounded flex flex-col justify-start items-start">
        <div className="text-white/50 text-13 mb-1">Logged as</div>
        <div className="text-white text-sm font-normal leading-tight">
          {profileData.email}
        </div>
      </div>

      {/* Divider */}
      <div className="w-full px-2">
        <div className="w-full h-px bg-white/15 my-1" />
      </div>

      <div
        className="h-9 self-stretch p-2 rounded flex-col justify-start items-start hover:bg-[var(--coal-700)] cursor-pointer"
        onClick={() => navigate("/profile", { state: { section: "plans" } })}
      >
        <div className="text-white text-sm font-normal">Plan and Tokens</div>
      </div>

      {/* Divider */}
      <div className="w-full px-2">
        <div className="w-full h-px bg-white/15 my-1" />
      </div>

      <div
        className="h-9 self-stretch p-2 rounded flex-col justify-start items-start hover:bg-[var(--coal-700)] cursor-pointer"
        onClick={() => navigate("/profile", { state: { section: "account" } })}
      >
        <div className="text-white text-sm font-normal">
          Account and Settings
        </div>
      </div>

      <div
        className="h-9 self-stretch p-2 rounded flex-col justify-start items-start hover:bg-[var(--coal-700)] cursor-pointer"
        onClick={() => navigate("/profile", { state: { section: "usage" } })}
      >
        <div className="text-white text-sm font-normal">Usage History</div>
      </div>

      {/* Join our Discord */}
      <div
        className="h-9 self-stretch p-2 rounded flex justify-between items-center hover:bg-[var(--coal-700)] cursor-pointer transition-colors duration-300"
        onClick={() => window.open("https://discord.gg/UprGQaBMDY", "_blank")}
      >
        <div className="text-white text-sm font-normal">Join our Discord</div>
        <div className="w-6 h-6 flex items-center justify-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            fill="var(--color-w-30)"
            viewBox="0 0 20 20"
          >
            <path d="M16.942 3.27A16.54 16.54 0 0 0 12.816 2c-.195.349-.372.707-.529 1.074a15.366 15.366 0 0 0-4.579 0A11.385 11.385 0 0 0 7.179 2c-1.425.243-2.814.67-4.129 1.273C.44 7.136-.269 10.903.085 14.617a16.63 16.63 0 0 0 5.06 2.54c.41-.55.773-1.135 1.084-1.747a10.76 10.76 0 0 1-1.706-.815c.143-.103.283-.21.418-.314a11.887 11.887 0 0 0 10.118 0c.137.111.277.218.418.314-.545.322-1.117.595-1.71.817.312.611.674 1.196 1.084 1.746a16.556 16.556 0 0 0 5.064-2.539c.415-4.307-.71-8.04-2.973-11.35ZM6.678 12.332c-.987 0-1.802-.895-1.802-1.996s.787-2.004 1.798-2.004c1.012 0 1.82.903 1.803 2.004-.017 1.101-.794 1.996-1.8 1.996Zm6.644 0c-.988 0-1.8-.895-1.8-1.996s.787-2.004 1.8-2.004 1.816.903 1.798 2.004c-.017 1.101-.792 1.996-1.798 1.996Z" />
          </svg>
        </div>
      </div>

      {/* Email Support */}
      <div
        className="h-9 self-stretch p-2 rounded flex justify-between items-center hover:bg-[var(--coal-700)] cursor-pointer transition-colors duration-300"
        onClick={() => {
          // Create a dynamic mailto link
          const mailToLink = `mailto:support@vudoo.ai`;
          window.open(mailToLink, "_self");
        }}
      >
        <div className="text-white text-sm font-normal">Email Support</div>
        <div className="w-6 h-6 flex items-center justify-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            fill="var(--color-w-30)"
            viewBox="0 0 20 20"
          >
            <path
              fillRule="evenodd"
              d="M1.19 4.545A2.47 2.47 0 0 0 1 5.5v9A2.5 2.5 0 0 0 3.5 17h13a2.5 2.5 0 0 0 2.5-2.5v-9a2.47 2.47 0 0 0-.19-.955l-5.295 4.217 2.686 3.896a.75.75 0 1 1-1.235.851l-2.639-3.827-.87.657a2.417 2.417 0 0 1-2.914 0l-.87-.657-2.639 3.827a.75.75 0 1 1-1.235-.851l2.686-3.896L1.19 4.545Zm.962-1.151L7.96 8.018l1.487 1.124a.917.917 0 0 0 1.106 0L12.04 8.02l5.808-4.625A2.488 2.488 0 0 0 16.5 3h-13c-.496 0-.959.145-1.348.394Z"
              clipRule="evenodd"
            />
          </svg>
        </div>
      </div>

      {/* Divider */}
      <div className="w-full px-2">
        <div className="w-full h-px bg-white/15 my-1" />
      </div>

      {/* Sign out - simplified button */}
      <div 
        className="h-9 self-stretch p-2 rounded flex justify-start items-center hover:bg-[var(--coal-700)] cursor-pointer"
        onClick={handleLogoutAndRedirect}
      >
        <div className="text-white text-sm font-normal">Sign out</div>
      </div>
    </div>
  );

  return (
    <div className="profile-circle-container">
      <Dropdown
        overlay={profileMenu}
        trigger={["click"]}
        placement="bottomRight"
      >
        <div
          className="profile-circle cursor-pointer"
          aria-label="User Profile"
          role="button"
          tabIndex={0}
        >
          {contextLoading ? (
            <Spin size="small" />
          ) : (
            <Avatar
              src={profileData.avatar}
              size="large"
              icon={<UserOutlined />}
            />
          )}
        </div>
      </Dropdown>
    </div>
  );
};

export default ProfileCircle;
